@keyframes spinnerRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
:local(.spinner) {
  text-align: center;
  height: 24px;
  width: 24px;
  line-height: 24px;
  margin: 0 auto;
  background-image: url('../../../img/loader.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  display: flex;
  justify-content: center;
  animation: spinnerRotation 1s 0s infinite linear;
}
.caption-container {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}
.caption {
  position: relative;
  line-height: 1.5;
  font-size: 16px;
  color: #7a6769;
  margin-top: 5px;
}
.small-spinner{
	width: 35px;
	margin-top: 0;
	height: 35px;
}
.small-spinner:after {
	display: none;
}