.panes .pane.pane-detail .invite-user-button {
  display: inline-block;
  width: 285px;
  margin-top: 209px;
}

.user-container {
  overflow: auto;

  .message-preview {
    max-width: 480px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.users-page {

  .user-info-toggle {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    font-size: 15px;
    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  .view-chat-history-link {
    display: block;
    margin-bottom: 24px;
    color: $brand-primary;
    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }

}

.user-detail-pane {
  flex-direction: row !important;
  .pane-header {
    position: relative;
  }
  .start-new-chat-btn {
    position: absolute;
    right: 0;
  }
  &.user-info-is-open {
    .start-new-chat-btn {
      position: absolute;
      display: inline-block;
      width: 120px;
      left: 4px;
    }
  }
  .user-info-toggle {
    position: absolute;
    right: 10px;
  }
}

.user-detail-info {
  flex: 1;
}