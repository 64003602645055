:local(.wrapper){
}
:local(.inner){
    padding-top: 30px;
    text-align: center;
}
:local(.inner) .fa{
    font-size: 70px;
    width: 110px;
    height: 110px;
    border: solid 3px #bdbdbd;
    border-radius: 55px;
    color: #bdbdbd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
}