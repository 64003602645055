:local(.wrapper){
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    /* background-color: #f4f4f4; */
}
:local(.inner){
    max-width: 365px;
    text-align: center;
}
:local(.inner) .fa-bolt{
    font-size: 70px;
    width: 110px;
    height: 110px;
    border: solid 3px #bdbdbd;
    border-radius: 55px;
    color: #bdbdbd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 30px;
}