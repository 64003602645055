.manage-tags {

  input:focus {
    border: solid 1px #2e7de1;
  }

  .label-input {
    position: relative;
  }

  .controls-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 0;
    width: 60px;
    height: 36px;
    img {
      cursor: pointer;
      width: 20px;
      height: 20px;
      &:first-child {
        margin-right: 6px;
      }
      &:last-child {
        margin-right: 8px;
      }
    }
  }

  .cancel-edit-tag {
    position: relative;
    top: 2px;
    cursor: pointer;
    color: #797979;
    padding-left: 8px;
  }

  .pane-content {
    padding: 0 !important;
  }

  .overall-config, {
    padding: 0 30px 20px 30px;
  }

  .detail-footer {
    button {
      margin-right: 10px;
    }
  }

  .tag-instructional {
    margin-bottom: 2px;
  }

  .tag-config {
    flex: 1;
    padding-left: 0;
    padding-right: 0;
    h5, p, .controls, .tag-row, .table-header {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .edit-icon {
    cursor: pointer;
    margin-right: 6px;
  }

  .unsaved-changes {
    margin-right: 8px;
    color: rgba(121, 121, 121, 0.9);
  }

  .feature-disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .content-body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 0 !important;
    width: 100%;
  }

  .detail-spinner {
    margin-left: -20px;
    margin-top: -20px;
  }

  .app-tooltip {
    margin-left: 4px;
  }

  .sub-header {
    -webkit-font-smoothing: subpixel-antialiased;
    hr {
      display: none;
    }
  }

  p {
    font-size: 13px;
  }

  h5 {
    font-weight: bold !important;
  }

  .input-with-addon {
    display: inline-block;
    position: relative;
    &.has-error {
      .input-with-addon-wrapper {
        border-color: $brand-danger;
      }
      .addon-text {
        color: $brand-danger;
      }
    }
    .label-input {
      display: inline-block;
    }
    .input-with-addon-wrapper {
      display: flex;
      width: 315px;
      height: 36px;
      padding-right: 8px;
      border: solid 1px rgba(121, 121, 121, 0.9);
      border-radius: 3px;
    }
    input {
      width: 100%;
      height: 100%;
      padding-left: 8px;
      padding-right: 8px;
      border: none;
      color: #000000;
    }
    .addon-text {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 60px;
      height: 100%;
      font-size: 13px;
      font-weight: normal;
      color: rgba(121, 121, 121, 0.9);
    }
  }

  .section-separator {
    position: relative;
    left: -30px;
    width: calc(100% + 60px);
    height: 1px;
    margin: 24px 0;
    border-bottom: 1px solid #d9d9d9;
  }

  /* .modal-body { */
  /*   overflow-x: hidden; */
  /*   overflow-y: auto !important; */
  /*   section h5 { */
  /*     margin-bottom: 12px; */
  /*   } */
  /* } */

  section {
    &.header-section {
      margin-bottom: 16px;
      p {
        font-size: 13px;
      }
      section {
        margin-top: 12px;
      }
    }
    &.options-section {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &.table-section {
      margin-top: 16px;
    }
  }

  .controls {
    display: inline-block;
    margin-top: 20px;
  }

  .section-separator.before-footer {
    margin: 24px 0 0;
    border-color: #f4f4f4;
  }

  .detail-footer {
    position: static;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-top: 1px solid #f4f4f4;
  }

  .section-separator.after-footer {
    margin: 0;
  }

}

.overall-config {
  .save-footer-section {
    margin-top: 8px;
    text-align: right;
  }
  .tag-config-section {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .label-checkbox + .label-checkbox {
      margin-top: 0;
      margin-left: 56px;
    }
  }
  .toggle-multiple-tags {
    margin-left: 24px;
  }
  &.is-submitting {
    input, .addon-text, .input-with-addon-wrapper {
      background: #e6e6e6;
    }
  }
}

.tag-config {

  padding-top: 16px;
  background-color: #f4f4f4;
  border-top: 1px solid rgba(0, 0, 0, 0.15);

  input {
    height: 36px !important;
    padding-left: 8px;
    padding-right: 8px;
    font-size: $font-size-base;
    background-color: #ffffff;
    border: 1px solid rgba(121, 121, 121, 0.9);
    border-radius: 3px;
  }

  .column-1 {
    min-width: 129px;
    width: 129px;
    padding-right: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    align-self: flex-start;
  }

  .column-2 {
    width: 100%;
  }

  .column-3 {
    width: auto;
    /* padding-right: 8px; */
    text-align: right;
  }

  .row-content, .inner-row-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    /* height: 46px; */
  }

  .inner-row-content {
    flex: 1;
  }

  .new-tag-row.row-content {
    display: block;
  }

  .add-tag-btn {
    display: flex;
    align-items: center;
    text-decoration: none;
    img {
      position: relative;
      top: -1px;
      margin-right: 10px;
    }
    color: $brand-primary;
  }

}

.tag-table {

  margin-top: 10px;

  a {
    cursor: pointer;
    &.disabled {
      cursor: default;
      /* color:  */
    }
  }

  .separator:before {
    content: "/";
    margin: 0 0.3em;
    color: $gray-light;
  }

  div[class*='column'] {
    /* outline: 1px solid red; */
  }

  .table-header {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    @extend .heading-light-cap;
    font-size: 13px;
    color: rgba(121, 121, 121, 0.9);
    font-weight: bold;
    .column-1 {
      padding: 0;
    }
  }

  .table-body {
    /* max-height: 300px; */
    /* overflow-y: auto; */

    &.scroller {
      max-height: 340px;
      overflow-y: auto;
    }
  }

  /* .table-row { */
  /*   outline: 1px solid red; */
  /*   width: 100%; */
  /*   border-bottom: 1px solid $gray-lighter; */
  /* } */

  .tag-row {
    display: flex;
    align-items: center;
    min-height: 58px;
    padding: 0;
    border-bottom: 1px solid #d9d9d9;
  }

  .tag-row-error {
    margin-top: -2px;
    margin-bottom: 8px;
  }

  .plain-tag-row {
    .column-1 {
      word-break: break-all;
    }

    &.disabled {
      /* color: $gray-light; */

      /* .text-primary__faded { */
      /*   color: rgba(68, 120, 188, 0.5); */
      /* } */

      /* .text-danger__faded { */
      /*   color: rgba(219, 68, 55, 0.5); */
      /* } */
      .edit-icon {
        opacity: 0.4;
      }
    }
  }

  .tag-description {
    padding: 8px 0;
    padding-right: 12px;
    word-break: break-all;
  }

}

.add-tag-row {
  .description-input input {
    padding-right: 36px;
  }
}

.new-tag-row {
  min-height: 46px;
  input {
    /* @extend .borderless; */
    /* border: solid 1px rgba(121, 121, 121, 0.9); */
    width: 100%;
    height: 100%;
    /* font-size: $font-size-base; */
  }

  .description-input input {
    padding-right: 60px;
  }

  label {
    width: 100%;

    input {
      min-width: 60px;
    }
    p {
      position: absolute;
    }
  }

  .label-input.has-error {
    p {
      display: none;
      /* background-color: white; */
      /* padding: 0 0 0 6px; */
    }
  }

  &.is-submitting {
    input {
      background: #e6e6e6;
    }
  }
}

.delete-tag-modal {
  width: 342px !important;
  .modal-content {
    min-width: 0 !important;
  }
  .modal-body {
    font-size: 13px;
    padding-top: 18px;
    padding-bottom: 18px;
  }
  button {
    font-size: 17px;
    font-weight: normal;
  }
  .btn-danger {
    margin-left: 20px !important;
  }
}