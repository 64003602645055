.context-menu {
  position: absolute;
  z-index: 4000;
  top: 100%;
  left: 4px;
  right: 4px;
  // width: 132px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  .context-menu-content {
    background: #fff;
  }
  .context-menu-inner {
    padding: 4px;
  }
}