@font-face {
  font-family: 'BerninoSans-CondensedBold';
  src: url('../src/fonts/BerninoSans-CondensedBold.woff') format('woff');
}

@font-face {
  font-family: 'BerninoSans-CondensedExtrabold';
  src: url('../src/fonts/BerninoSans-CondensedExtrabold.woff') format('woff');
}

@font-face {
  font-family: 'BerninoSans-CondensedLight';
  src: url('../src/fonts/BerninoSans-CondensedLight.woff') format('woff');
}

@font-face {
  font-family: 'BerninoSans-CondensedRegular';
  src: url('../src/fonts/BerninoSans-CondensedRegular.woff') format('woff');
}

@font-face {
  font-family: 'BerninoSans-CondensedSemibold';
  src: url('../src/fonts/BerninoSans-CondensedSemibold.woff') format('woff');
}


/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../src/fonts/roboto-v16-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin'), local('Roboto-Thin'),
       url('../src/fonts/roboto-v16-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../src/fonts/roboto-v16-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../src/fonts/roboto-v16-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../src/fonts/roboto-v16-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../src/fonts/roboto-v16-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../src/fonts/roboto-v16-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../src/fonts/roboto-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../src/fonts/roboto-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../src/fonts/roboto-v16-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../src/fonts/roboto-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../src/fonts/roboto-v16-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../src/fonts/roboto-v16-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../src/fonts/roboto-v16-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../src/fonts/roboto-v16-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../src/fonts/roboto-v16-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../src/fonts/roboto-v16-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../src/fonts/roboto-v16-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../src/fonts/roboto-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('../src/fonts/roboto-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../src/fonts/roboto-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../src/fonts/roboto-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../src/fonts/roboto-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../src/fonts/roboto-v16-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* TYPOGRAPHY */
a,
a:hover,
a:active,
a:focus {
  outline: 0;
  &.text-white{
    color: #fff;
  }
}

a:hover {
  cursor: pointer;
}

.pre{
  white-space: pre;
}
.pre-line{
  white-space: pre-line;
}
.text-primary {
  color: #4478bc !important;
}
.text-success {
  color: #27c24c !important;
}
.text-warning {
  color: #ff902b !important;
}
.text-danger {
  color: #db4437 !important;
}
.text-info {
  color: #449fd7 !important;
}
.text-gray-darker {
  color: #1b1b1b;
}
.text-gray-dark {
  color: #515151;
}
.text-gray {
  color: #cacaca;
}
.text-gray-light {
  color: #e8e8e8;
}
.text-gray-lighter {
  color: #f2f2f2;
}
.text-default {
  color: #1b1b1b;
}
.text-white{
  color: #fff;
}
.text-center{
  text-align: center;
}
.light{
  font-weight: 300;
}
.semi-bold{
  font-weight: 500;
}
.bold{
  font-weight: 700;
}
.fs-13{
  font-size: 13px;
}
.fs-14{
  font-size: 14px;
}
.fs-15{
  font-size: 15px;
}
.fs-16{
  font-size: 16px;
}
.fs-17{
  font-size: 17px;
}
.fs-18{
  font-size: 18px;
}
.fs-20{
  font-size: 20px;
}
.fw-300 {
  font-weight: 300;
}
.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700;
}

.all-caps{
  text-transform: uppercase;
}
.normal-caps{
  text-transform:none;
}
input:focus,
textarea:focus{
  outline: 0 !important;
  box-shadow: 0 !important;
}
.link{
  color: $brand-primary;
  cursor: pointer;
}
.underline{
  text-decoration: underline;
}

.heading-light-cap{
  font-size: 13px;
  color: rgba(50, 51, 58, 0.4);
  text-transform: uppercase;
}

.tag{
  border: 1px solid rgba(45, 124, 225, 0.33);
  font-size: 11px;
  text-transform: uppercase;
  padding: 1px 8px;
  letter-spacing: .7px;
  color: $brand-primary;
  display: inline-block;
}

.fade{
  opacity: .5
}
.fade-hard{
  opacity: .4;
}
.font-lato{
  font-family: 'Lato', sans-serif;
}
.no-underline:hover{
  text-decoration: none;
}

.text-wrap-word {
  word-break: break-all;
}

.font-reset {
  font-size: $font-size-base;
  font-weight: normal;
  text-transform: none;
  color: $text-color;
}

.plain-text {
  font-size: $font-size-base;
  color: $text-color;
  background: none;
  text-transform: none;
  text-decoration: none;
  font-weight: normal;
}

code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

pre {
  display: block;
  padding: 10px;
  margin: 0 0 10.5px;
  font-size: 14px;
  line-height: 1.42857;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}