/* csslint ignore:start */
:local(.WidgetItem:first-child) {
/* csslint ignore:end */
  border-top: 1px solid #bdbdbd;
}
/* csslint ignore:start */
:local(.WidgetItem.isEditing:first-child) {
/* csslint ignore:end */
  border-top: 1px solid rgba(189, 189, 189, 0.3);
}

:local(.WidgetItem) {
  border-bottom: 1px solid #bdbdbd;
}

:local(.WidgetItem.isEditing) {
  border-bottom: 1px solid rgba(189, 189, 189, 0.3);
}

:local(.WidgetItem.isEditMode) {
  border-bottom: 1px solid rgba(189, 189, 189, 0.3);
}

:local(.caretIcon) {
  background: url(../../../../img/icon-page-up-big.svg) center no-repeat;
  width: 30px;
  height: 30px;
}

:local(.Caution) {
  margin-right: 10px;
}

:local(.Caution .icon-button) {
  padding: 0 0 !important;
}

:local(.Trash) {
  background: url(../../../../img/trash-icon.svg) center no-repeat;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

:local(.Trash:hover) {
  background: url(../../../../img/delete-hover.svg) center no-repeat;
}

:local(.caretIcon:hover) {
  background: url(../../../../img/page-up-big-hover.svg) center no-repeat;
}

:local(.iconsContainer) {
  display: flex;
};

:local(.Steps) {}

:local(.isOpen .Steps) {}

:local(.keepOpen .Panel) {
  cursor: default;
}

:local(.Panel) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 30px;
  cursor: pointer;
}

:local(.Edit) {
  cursor: pointer;
  margin-right: 12px;
}

:local(.editInactive) {
  pointer-events: none;
  opacity: 0.5;
  margin-right: 12px;
}

:local(.Toggle) {
  /* cursor: pointer; */
  transform: rotate(180deg);
}

:local(.ToggleActive) {
  transform: rotate(0) !important;
}

:local(.Config:first-child) {
  border-top: 1px solid #bdbdbd;
}

:local(.Config:last-child) {
  border-bottom: none;
}

:local(.Config) {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 60px;
  min-height: 60px;
  background: #fff;
  border-bottom: 1px solid #bdbdbd;
}

:local(.ApiConfig:first-child) {
  border-top: 1px solid #bdbdbd;
}

:local(.ApiConfig:last-child) {
  border-bottom: none;
}

:local(.ApiConfig) {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 60px;
  min-height: 60px;
  background: #fff;
  border-bottom: 1px solid #bdbdbd;
  justify-content: space-between;
}

:local(.UnstyledList) {
  height: auto;
}

:local(.UnstyledList ul) {
  height: auto;
  list-style-type: none;
  margin-bottom: 0
}

:local(.UnstyledList li:nth-child(2)) {
  padding-top: 5px;
  padding-bottom: 5px;
}

:local(.UnstyledList li span:first-child) {
  font-weight: bold;
}

:local(.UnstyledList li span:last-child) {
  font-weight: 300;
}

:local(.ApiDetails) {
  display: flex;
  font-size: 13px;
  color: #797979;
}

:local(.ApiDetails ul) {
  padding-left: 0
};

:local(.Reset) {
  color: #2e7de1;
  font-size: 13px;
}

:local(.BulletedConfig) {
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

:local(.BulletedConfig ul) {
  margin: 0;
  padding: 0;
}

:local(.BulletedConfig li) {
  margin: 0;
  padding: 0;
}

:local(.ConfigDescriptor) {
  margin-left: -4px !important;
}

:local(.ConfigDescriptor li) {
  color: #000;
  list-style-type: none;
}

:local(.Bullet) {
  /* position: absolute; */
}

:local(.Bullet li) {
  color: #000;
  list-style-type: default;
  margin-left: 70px;
  font-size: 13px;
  position: relative;
  top: 1px;
}

:local(.Action) {
  position: relative;
  cursor: pointer;
  color: #2e7de1;
}

:local(.ScriptConfigAction) {
  cursor: pointer;
  color: #2e7de1;
  /* color: #797979; */
}

:local(.Weak) {
  margin-left: 4px;
  color: #797979;
}

:local(.AppId) {
  font-size: 13px;
  color: #797979;
}

:local(.shouldMask .Panel) {
  cursor: default;
  opacity: 0.3;
}

:local(.isEditing .Panel) {
  cursor: default;
  opacity: 0.3;
  border-bottom: 1px solid #bdbdbd;
}

:local(.Button) {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: none;
}

