:local(.EditPanel) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 70px;
  background: #fff;
}

.isSubmitting {
  /* <TODO> Submitting state */
}

:local(.Input) {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  transition: border 240ms linear;
}

:local(.Input:focus),
:local(.Input:active) {
  border: 1px solid #2e7de1;
}

:local(.Controls) {
  margin-left: 10px;
}

:local(.Img) {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

:local(.Img + .Img) {
  margin-left: 10px;
}

:local(.isDisabled) {
  opacity: 0.3;
}