/* Split view panes */

.panes {
  display: flex;
  height: 100%;
  flex-direction: row;
  &.panes-1-1 .pane {
    &.pane-list,
    &.pane-detail {
      width: 50%;
    }
  }
  .pane {
    flex: 1;
    display: flex;
    vertical-align: top;
    border: 1px solid #8e8e8e;
    &.pane-list {
      width: $pane-list-width;
      overflow: hidden;
      flex-direction: column;
      max-width: $pane-list-width;
      min-width: $pane-list-width;
      position: relative;
      background: #fff;
      border-right: 1px solid #bdbdbd;
      .pane-header {
        flex-direction: column;
        align-items: start;
        padding-bottom: 16px;
        border-bottom-color: transparent;

        > button {
          display: flex;
          align-items: center;
          padding: 4px 10px 4px 4px;
          margin: 20px 0 8px 14px;

          text {
            display: inline-block;
            width: 64px;
          }
        }

        > div {
          display: flex;
          align-items: center;

          p {
            margin: 0 8px 0 14px;
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            color: #29302e;
          }

          small {
            font-size: 14px;
            color: #716f6f;

            &::first-letter {
              text-transform: capitalize;
            }
          }
        }
      }
      .list-search {
        padding: 9px 12px;
        background: #f7f7f7;
        box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.15);
        .search-input {
          border-radius: 20px;
          background: #fff;
          border: 1px solid #d7d7d7;
          text-align: center;
          padding: 3px 0;
          font-size: 15px;
          height: auto;
          &::-webkit-input-placeholder {
            text-align: center;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            text-align: center;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            text-align: center;
          }
          &:-ms-input-placeholder {
            text-align: center;
          }
          &::-webkit-input-placeholder:before {
          }
        }
      }
      .list {
        flex: 1;
        overflow-y: auto;
        display: flex;
        position: relative;

        .infinite-scroll:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }

        .empty-list-content {
          padding: 6rem 20px 0 20px;
          text-align: center;
          border-bottom: 0 !important;

          p {
            font-size: 16px;
            font-family: "Open Sans";
          }

          img {
            margin-bottom: 15px;
            height: 8rem;
            width: 9rem;
          }

          #title {
            font-weight: 600;
            color: #29302e;
          }

          #description {
            font-weight: normal;
            color: #7a6769;
          }

          h4 {
            font-size: 20px;
          }

          h3 {
            font-size: 26px;
            padding-bottom: 45px;
          }
        }
        &.recent-campaigns-list {
          .list-item {
            flex-direction: column;
          }
          li {
            .msg {
              margin-top: 5px;
              overflow: hidden;
              p {
                line-height: 18px;
                letter-spacing: 0.2px;
              }
            }
            .tag {
              margin-top: 5px;
            }
            .count {
              letter-spacing: 0.9px;
              font-size: 13px;
            }
          }
        }
        &.recent-messages-list li {
          .msg {
            position: relative;
            margin-top: -3px;
            overflow: hidden;
          }
          .tag {
            margin-top: 6px;
          }
        }
        &.users-list .list-item {
          height: 75px;
          .user-image {
            //align-items: center;
          }
          .name {
            max-width: 165px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        li {
          padding: 10px 15px;
          height: 110px;
          overflow: hidden;
          box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.15);
          display: block;
          &.active {
            background: rgba(45, 124, 225, 0.07);
            box-shadow: inset 2px 0 0 $brand-primary;
          }
        }
        a {
          &.list-item {
            text-decoration: none;
            &.active{
              ConvoCard {              
                &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 2px;
                height: 100%;
                background: $brand-primary;
                }
              }
            }
            .msg {
              overflow: hidden;
              margin-bottom: 10px;
            }
            .user-image {
              display: flex;

              img {
                border-radius: 9999px;
              }
            }
            .list-item-extension {
              margin-left: auto;
            }
          }
        }

        .list-item {
          .message-preview {
            width: 250px;

            .preview-container {
              margin-bottom: 10px;
              position: relative;
              display: flex;
              align-items: center;
            }

            .message-text {
              word-break: break-word;
              width: 230px;
            }
            .unread-bubble {
              color: #2e7de1;
            }
          }
        }
        .icon path {
          width: 15px;
          height: 15px;
        }
        .agent-name {
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #7a6769;
        }
        .agent-name.truncate {
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .sub-nav-item {
          .list-item {
            padding: 0 20px;
            height: 66px;
          }
        }
      }
      .list > div > div:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      }
    }
    &.pane-detail {
      position: relative;
      background: #fff;
      overflow: hidden;
      height: 100%;
      vertical-align: bottom;
      width: $pane-detail-width;
      border-left: 1px solid #bdbdbd;
      &.error-boundary {
        vertical-align: middle;
        text-align: center;
      }
      .pane-content {
        padding: 30px;
        overflow: auto;

        textarea {
          resize: none;
        }

        h5 {
          font-size: 15px;
          font-weight: 500;
          margin-top: 0;
        }

        hr {
          margin-top: 0;
        }

        p {
          font-size: 13px;
          .btn {
            font-size: 13px;
          }
        }
      }

      & > .full-height {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px) !important;
      }

      .pype-completeness {
        position: absolute;
        top: 195px;
        left: 0;
        margin: 0 0 0 57px;

        .pype-completeness-steps {
          border-left: 0 none;
        }
      }

      .empty-pane {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #fff;
        color: #000;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;
        justify-content: center;

        flex-flow: column;

        & > * {
          text-align: center;
        }

        h3 {
          line-height: 35px;
        }

        .empty-pane-text {
          margin-top: 1.9rem;
          font-size: 1.6rem;
          font-weight: 600;
          color: #29302e;
          font-family: "Open Sans";
        }
      }
    }
    .pane-header {
      min-height: $pane-header-height;
      background: white;
      padding: 4px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      position: relative;
      border-bottom: 1px solid #bdbdbd;
      .title {
        font-size: 17px;
      }
      .right {
      }
      .flex-right-header {
        position: absolute;
        right: 10px;
        top: 25%;
      }
      &.white {
        background: #fff;
      }
    
    }
  }
}

.break-word {
  word-break: break-all;
}
