:local(.wrapper){
	background: #fff;
	overflow-y:auto;
	display: flex;
	flex-direction: column;
	height: 100%;
}
:local(.wrapperInner){
	flex: 1;
	display: flex;
	justify-content: normal;
	flex-direction: column;
}
:local(.footer){
	padding: 17px 30px;
	position: relative;
	background: #fff;
}
:local(.footer):before{
	content: "";
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	height: 10px;
	box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.2);
	z-index: 1;
}
:local(.growthCount){
	 color: #27ba68;
}
:local(.btnInvite){
	  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #2e7de1;
  padding: 8px 20px;
    font-size: 17px;
  font-weight: 500;
  color: #2e7de1;
}
