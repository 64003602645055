:local(.ChatCircle) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  background: #797979;
  border-radius: 100px;
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.3);
  transition: background 160ms linear;
}