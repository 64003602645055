:local(.wrapper){
  flex: 1;
  background: #fff;
  display: flex;
  flex-direction: column;
}
:local(.NoSkills){
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #797979;
  height: 100%;
}
:local(.NoSkills) h4{
  font-weight: normal;
  color: #797979;
}
:local(.header){
  display: flex;
  align-items: center;
}
:local(.header) button{
  margin-left: auto;
}
:local(.skillsList){
  overflow-y: auto;
  flex: 1;
  transition:all .3s ease;
}
:local(.skillsList).scrolled{
  box-shadow: inset 0 8px 8px -8px rgba(0,0,0,.4);
}
