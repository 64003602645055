.btn {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
  padding: $padding-base-vertical $padding-large-horizontal;
  &.btn-danger-bordered {
    border-color: $brand-danger;
    background: #fff;
    color: $brand-danger; }
  &.btn-default {
    border-color: $brand-primary;
    color: $brand-primary;
    background: #fff; }
  &.btn-default:hover {
    background-color: #eff5fd;
    border-color: #2e7de1;
    color: #2e7de1; } }

.horizontal-group {
  &>* {
    vertical-align: middle;
    margin-right: 10px;
    &:last-child {
      margin-right: 0; } } }
