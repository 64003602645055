:local(.Modal){
  width: 700px;
}
:local(.Modal) .modal-content{
  min-width: 700px;
  max-width: 700px;
}
:local(.Modal) .modal-body{
  display: flex;
  height: 527px;
  padding: 0;
}
:local(.Modal) .modal-footer{
  border-top:1px solid #bdbdbd;
  padding: 10px;
  display: flex;
}
:local(.footerRight){
  margin-left: auto;
}
:local(.footerRight) .unsaved{
  /*font-size: 17px;*/
  color: #747474;
}
:local(.agentDetailsWrapper){
  flex: 1;
  padding: 20px;
  min-width: 0;
}
:local(.skillsWrapper){
  width:358px;
  /*box-shadow: inset 1px 0 0 0 #bdbdbd;*/
  display: flex;
  border-left: 1px solid #bdbdbd;
}
