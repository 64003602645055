:local(.SkillItem:first-child) {
}

:local(.SkillItem) {
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.15);
}

:local(.keepOpen){

}

:local(.isOpen){

}

:local(.keepOpen .Panel) {
  cursor: default;
}

:local(.Panel) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 30px;
  cursor: pointer;
}

:local(.Agents) {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 60px;
  min-height: 60px;
  background: #fff;
  box-shadow: inset 0 3px 7px -3px #bdbdbd;

}

:local(.PaddedAgents) {
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

:local(.LeftContent) {
  display: flex;
  align-items: center;

  span {
    vertical-align: middle;
  }
}

:local(.RightContent) {
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
    font-size: 13px;
    font-weight: 500;
    text-align: right;
    color: #797979;
  }
}

:local(.Edit) {
  cursor: pointer;
  margin-right: 12px;
  width: 30px;
  height: 30px;
}

:local(.General) {
  cursor: default;
  opacity: 0.5;
  margin-right: 12px;
  width: 30px;
  height: 30px;
}

:local(.Toggle) {
  transform: rotate(180deg);
}

:local(.ToggleActive) {
  transform: rotate(0) !important;
}

:local(.Action) {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  color: #797979;
  font-size: 13px;
  line-height: 20px;

  span:not(:last-child)::after {
    content: "\00b7";
    padding: 0 6px;
  }
}
