:local(.dropdownWrapper){
  min-height: 215px; /* IE fix */
}
:local(.dropdownWrapperExpanded){
  min-height: 300px; /* IE fix */
}
:local(.agentsTableWrapper) .public_fixedDataTableCell_main,
:local(.agentsTableWrapper) .public_fixedDataTableRow_highlighted,
:local(.agentsTableWrapper) .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main{
  background: transparent;
  box-shadow: inset 0 -1px 0 0 #f4f4f4;
}
:local(.agentsTableWrapper) .fixedDataTableLayout_rowsContainer > .fixedDataTableRowLayout_rowWrapper .public_fixedDataTableCell_main{
  box-shadow: none;
}

:local(.agentsTableWrapper) .fixedDataTableCellGroupLayout_cellGroup > div:first-child,
:local(.agentsTableWrapper) .fixedDataTableCellGroupLayout_cellGroup > div:first-child .public_fixedDataTableCell_cellContent{
  padding: 0;
}

:local(.editCell){
  padding: 0;
}

:local(.skillCount){
  text-decoration: underline;
}
:local(.agentsTableWrapper) .cell-overflow .public_fixedDataTableCell_wrap3{
  position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   box-sizing: border-box;
   display: block;
   width: 100%;
   display: flex;
   align-items: center;
}
:local(.agentsTableWrapper) .cell-overflow .public_fixedDataTableCell_cellContent{
  box-sizing: border-box;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}
