:local(.MessageWrapper) {
  pointer-events: none;
  margin-top: 5px;
  margin-bottom: 5px;
}

:local(.isLastMessage) {
  /* outline: 1px solid red; */
  pointer-events: auto;
}

:local(.isLastInGroup) {
  /* margin-bottom: 6px; */
}

:local(.isVideo) {
  pointer-events: auto;
}

:local(.Timestamp) {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
}