:local(.row){
  padding: 11px 20px;
  box-shadow: inset 0 -0.5px 0 0 #bdbdbd;
  display: flex;
  align-items: center;
}
:local(.row) p{
  flex: 1;
  max-width: 289px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
