:local(.ChatContent) {
  flex: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 12px;
}

:local(.Timestamp) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  margin-top: 6px;
}