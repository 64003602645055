:local(.DeleteWarningModal) {
  position: absolute;
  top: 42% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  overflow: visible !important;
  width: 342px !important;
  height: 206px !important;
  border-radius: 3px !important;
  outline: none !important;
}

:local(.ModalContent) {
  width: 342px !important;
  min-width: auto !important;
  height: 206px !important;
  max-width: none !important;
  border-radius: 3px !important;
}

:local(.ModalHeader) {
  height: 56px !important;
  font-size: 17px;
  border-radius: 3px 3px 0 0 !important;
}

:local(.ModalBody) {
  height: 134px;
  padding: 20px;
  /* box-shadow: 0 1px 0 0 #bdbdbd; */
}

:local(.Footer) {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

:local(.CloseButton) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93px;
  height: 38px;
  padding: 0;
  font-size: 17px;
  border-radius: 3px;
  border: 1px solid #797979;
  color: #797979;
  background: #fff;
}

:local(.SaveButton) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 89px;
  height: 38px;
  margin-left: 20px;
  padding: 0;
  font-size: 17px;
  border-radius: 3px;
  border: none;
  color: #fff;
  background: #db4437;
}

:local(.Info) {
  font-size: 13px;
}