:local(.RadioButton) {
  position: relative;
  cursor: pointer;
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  border: 2px solid #bdbdbd;
  border-radius: 32px;
}

:local(.RadioButton:after) {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  left: 3px;
  top: 3px;
  background-color: none;
  border-radius: 32px;
}

:local(.Checked) {
  border-color: #2e7de1;
  transition: all 200ms ease-out;
}

:local(.Checked:after) {
  background-color: #2e7de1;
  transition: all 200ms ease-out;
}