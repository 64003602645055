:local(.Webchat) {}

:local(.Info) {
  padding: 30px;
}

:local(.Heading) {
  font-weight: bold;
  margin-bottom: 8px;
}

:local(.Text) {
  font-size: 13px;
  margin-bottom: 0;
}
