
/* SIDEBAR */
.sidebar{
  background-color: $sidebar-bg;
  padding: 0;
  margin-right: 6rem;
  width: 24.8rem;
  // width: $sidebar-width;
  color: #fff;
  // box-shadow: 1px 0px 1px 0 rgba(0, 0, 0, 0.2);
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // z-index: 997;
  // display: flex;
  // flex-direction: column;
  // overflow-y:auto; 
  .agent-status {
    width: 120px;
    height: 47px;
    box-shadow: inset 0 -1px 0 0 #bdbdbd;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 16px 20px;

    .status-box {
      width: 8px;
      height: 8px;
      display: inline-block;
      margin-top: 4px;
    }

    .online {
      background-color: #35bb66;
    }

    .away {
      background-color: #fdbd39;
      border-radius: 999px;
    }

    .status {
      font-size: 13px;
      color: #000;

      .Select-control {
        background: none;

        .Select-placeholder {
          padding: 0;
        }

        .Select-arrow {
          top: 5px;
        }
      }
    }



  }

  /* Sidebar navigation */
  .nav-sidebar {
    padding-top: 13px;
    position: relative;
    flex: 1;
  }

  .nav-sidebar > li > a {
    color: #000;
    font-size: 12px;
    text-align: center;
    padding: 7px 0;
    margin: 7px;
    border-color: transparent;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    &.active{
      border-color: $brand-primary;
      .title{
        color: $brand-primary;
      }
      .icon-overview{
        background-image: url(../src/img/nav-overview-active.svg)
      }
      .icon-broadcasts{
        background-image: url(../src/img/nav-broadcasts-active.svg)
      }
      .icon-messages{
        background-image: url(../src/img/nav-messages-active.svg)
      }
      .icon-users{
        background-image: url(../src/img/nav-users-active.svg)
      }
      .icon-invite{
        background-image: url(../src/img/nav-invite-active.svg)
      }
      .icon-admin{
        background-image: url(../src/img/nav-admin-active.svg)
      }
    }
    .msg-count{
      position: absolute;
      top: 9px;
      right: 9px;
      border-radius: 25px;
      height: 25px;
      width: 25px;
      text-align: center;
      padding-top: 8px;
    }
    .title{
      text-transform: uppercase;
      margin: 0;
      margin-top: 5px;
      color: #474747;
      &.grayed {
        color: #999;
      }
    }
    .no-pype-logo{
      border: 1px solid black;
      img {
        visibility: hidden;
      }
    }
  }

  .nav-sidebar > li > a:hover {
    border-color: $brand-primary;
  }
  .nav-sidebar > li > a:hover,
  .nav-sidebar > li > a:focus,
  .nav-sidebar > .active > a,
  .nav-sidebar > .active > a:hover,
  .nav-sidebar > .active > a:focus{
    background: transparent !important;
  }
}

.sidebar-icon{
  width: 38px;
  height: 38px;
  display: block;
  margin: 0 auto;
  background-repeat: no-repeat;
  vertical-align: middle;
  background-size: contain;
  &.icon-broadcasts{
    background-image: url(../src/img/nav-broadcasts-inactive.svg)
  }
  &.icon-overview {
    background-image: url(../src/img/nav-overview-inactive.svg)
  }
  &.icon-messages{
    background-image: url(../src/img/nav-messages-inactive.svg)
  }
  &.icon-users{
    background-image: url(../src/img/nav-users-inactive.svg)
  }
  &.icon-invite{
    background-image: url(../src/img/nav-invite-inactive.svg)
  }
  &.icon-support{
    background-image: url(../src/img/nav-support.svg)
  }
  &.icon-admin{
    background-image: url(../src/img/nav-admin-inactive.svg)
  }
}
