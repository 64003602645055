:local(.popup){
  width: 320px;
}
:local(.container){
  width: 320px;
}

:local(.selectWrapper){
	flex: 1;
}
:local(.selectWrapper) .Select-menu-outer {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #d6d6d6;
  margin-top: 0;
}
:local(.selectWrapper) .Select-menu-outer .Select-option{
	padding: 10px;
	box-shadow: inset 0 -1px 0 0 #e5e5e5;
	color: #000;
}
:local(.selectWrapper) .Select-menu-outer .Select-option:hover{
 box-shadow: inset 2px 0 0 0 #2e7de1, inset 0 -1px 0 0 #e5e5e5;
}
:local(.selectWrapper) .Select-menu-outer .Select-option.is-selected{
	position: relative;
}
:local(.popoverWrapper) .Select-option.is-selected:after{
  display: none !important;
}
:local(.popoverWrapper) .is-selected:after{
  display: none !important;
}

:local(.selectWrapper) .Select-menu-outer .Select-option.is-disabled{
	color: #bdbdbd;
	background-color: #f4f4f4;
}
:local(.selectWrapper) .is-open .Select-control{
 border-color: #2e7de1;
 border-radius: 5px;
}
:local(.selectWrapper) .is-open .Select-arrow{
	transform:rotateZ(-180deg);
}
:local(.selectWrapper) .Select-value{
	color: #000;
}
:local(.selectWrapper) .is-disabled .Select-control{
	border-color: #CACACA;
}
:local(.selectWrapper) .is-disabled .Select-control .Select-arrow{
	border-top-color: #CACACA;
}
:local(.selectWrapper) .is-disabled .Select-control .Select-placeholder{
	color: #CACACA;
}

:local(.selectWrapper) .pull-right{
  display: none;
}

:local(.hint){
  text-transform: none;
  font-weight: normal;
}

:local(.skillOptionLeft){
  display: inline-block;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}
:local(.skillOptionRight){
  font-size: 12px;
  color: #797979;
}

:local(.emptyStateBody){
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
:local(.emptyStateButtons){
  display: flex;
  justify-content: center;
  flex-direction: row;
}
