:local(.chatCountBarBg){
	width: 300px;
  height: 26px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}
:local(.chatCountBarBg){
  background-color: #f4f4f4;
}
:local(.chatLimitHeader){
	position: absolute;
	right: 5px;
}
:local(.chatCountBarBg) .chatCountBar{
	position: absolute;
	left: 0;
  background-color: #dedede;
  height: 26px;
  overflow: hidden;
}
:local(.chatCountBarBg) .leftCount{
	position: absolute;
	left: 10px;
	z-index: 1;
	line-height: 26px;
}
:local(.chatCountBarBg) .rightCount{
	position: absolute;
	right: 10px;
	z-index: 1;
	line-height: 26px;
}

:local(.chatCountBarBg).total{
	background-color: #ffffff;
  border: solid 2px #2e7de1;
  margin-top: 8px;
}
:local(.chatCountBarBg).total .chatCountBar{
	background: #2e7de1;
}
:local(.chatCountBarBg).total .leftCount,
:local(.chatCountBarBg).total .rightCount{
	font-weight: bold;
	line-height: 22px;
}

:local(.chatCountBarBg).total .chatCountBar .leftCount{
	white-space: nowrap;
	color: #fff;
}

:local(.emptyAgentsMessage){
  padding: 100px 0;
  text-align: center;
}

:local(.cellNoLeftPadding) .public_fixedDataTableCell_cellContent{
	padding-left: 0;
}
:local(.skillsInfo){
	padding: 8px 15px;
	font-size: 13px;
	color: rgba(121, 121, 121, 0.9);
}
