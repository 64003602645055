:local(.ChatAnchor) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 222px;
  min-width: 222px;
  max-width: 222px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  background: #797979;
  border-radius: 26px 26px 0 0;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.3);
  transition: background 160ms linear;
}

:local(.top-left) {
  border-radius: 0 0 26px 26px;
}

:local(.top-right) {
  border-radius: 0 0 26px 26px;
}