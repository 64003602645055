:local(.FormattedChatBox) {
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  max-height: 116px;
  max-width: 100%;
  padding: 0 20px;
  border-top: 1px solid #efefef;
  background-color: #f8fbff;
}

:local(.TextBox) {
  display: flex;
  width: 100%;
  height: 36px;
  max-width: 100%;
  max-height: 76px;
  border: none;
}

:local(.Input) {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 76px;
  padding: 8px 16px;
  border: none;
  outline: none;
  border-radius: 100px;
  background: none;
  resize: none;
  overflow-y: auto;
  color: #6d89af;
  caret-color: #3389ff;
  text-align: center;
  font-family: Open Sans, Segoe UI, Trebuchet MS, sans-serif;
  font-size: 16px;

  &::placeholder {
    color: #6d89af;
  };
}

:local(.BrandedContainer) {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}
