:local(.wrapper){
  width: 100%;
  box-shadow:  inset 0 -0.5px 0 0 #bdbdbd;
}
:local(.labelInput){
  width: 100%;
}
:local(.labelInput).has-error .label-text{
  color: #db4437 !important;
}

:local(.labelInput) .label-text{
  text-transform: none;
  font-size: 15px;
}
:local(.labelInput) input{
  width: 100%;
  padding-right: 55px;
}

:local(.inputWrapper){
  position: relative;
}

:local(.charLimit){
  position: absolute;
  right: 10px;
  top: 38px;
  text-align: right;
  color: #797979;
  font-size: 13px;
  pointer-events: none;
}
