:local(.DesignConfig) {
  height: 100%;
}

:local(.DesignConfig section) {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}

:local(.DesignConfig section:last-child) {
  border-bottom: none;
}

:local(.Heading) {
  font-weight: bold;
  margin-bottom: 10px;
}

:local(.StyleOptions) {
  display: flex;
}

:local(.StyleOption) {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

/* :local(.StyleOption:hover) { */
/*   background: #eff5fd; */
/*   border: 1px solid #2e7de1; */
/* } */

:local(.StyleOption1) {
  width: 140px;
  height: 132px;
  margin-right: 30px;
}

:local(.StyleOption2) {
  width: 242px;
  height: 132px;
}

:local(.StyleOptionIsSelected) {
  background: #eff5fd;
  border: 1px solid #2e7de1;
}

:local(.RadioOption) {
  display: flex;
  align-items: center;
}

:local(.RadioButton) {
  position: relative;
  top: -1px;
  margin-right: 6px;
}

:local(.ResetButton) {
  cursor: pointer;
  float: right;
  font-weight: normal;
  font-size: 15px;
  color: #2e7de1;
}

:local(.InputHeading) {
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: bold;
  color: #797979;
  text-transform: uppercase;
}

:local(.PositionOption) {
  display: flex;
  align-items: center;
  height: 36px;
}

:local(.PositionBox) {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 20px;
  top: -1px;
  margin-right: 10px;
  border-radius: 1px;
  background-color: #f4f4f4;
  border: 1px solid #797979;
  border-radius: 1px;
}

:local(.PositionBoxInner) {
  position: absolute;
  width: 10px;
  height: 8px;
  border-radius: 1px;
  background-color: #797979;
}

:local(.PositionBoxInner.top-left) {
  left: 2px;
  top: 2px;
}

:local(.PositionBoxInner.top-right) {
  right: 2px;
  top: 2px;
}

:local(.PositionBoxInner.bottom-left) {
  left: 2px;
  bottom: 2px;
}

:local(.PositionBoxInner.bottom-right) {
  right: 2px;
  bottom: 2px;
}

:local(.Ratio) {
  margin-top: 12px;
}

:local(.Ratio .TextInput) {
  width: 182px;
}

:local(.InputWrapper) {
  display: inline-block;
}

:local(.TextInputWrapper) {
  position: relative;
  display: inline-block;
}

:local(.TextInput) {
  width: 190px;
  height: 36px;
  padding-left: 8px !important;
  padding-right: 8px !important;
  background: #ffffff;
  border: 1px solid #797979 !important;
  border-radius: 5px !important;
}

/* :local(.TextInputLabel) { */
/*   position: absolute; */
/*   right: 0; */
/*   top: 0; */
/*   width: 36px; */
/*   height: 36px; */
/*   background: #e5e5e5; */
/*   text-align: center; */
/* } */

:local(.LockRatio) {
  cursor: pointer;
  align-self: flex-end;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 2px;
}

:local(.LockRatio img) {
  position: relative;
  top: -1px;
}

:local(.Unit) {
  font-size: 13px;
  font-weight: normal;
  color: #797979;
  text-transform: none;
}

:local(.QuietInfo) {
  margin-top: 6px;
  font-size: 13px;
  color: #797979;
}

:local(.Dropdown > div:first-child) {
  height: 36px;
  border: 1px solid #797979;
  border-radius: 5px;
}

:local(.ColorPickerSection) {
  display: inline-block;
}

:local(.ColorPicker) {
  display: flex;
}

:local(.ColorInput) {
  width: 36px;
  height: 36px;
  border-radius: 5px 0 0 5px;
  border-color: #797979;
  background: #fff;
}

:local(.ColorTextInput) {
  width: 154px;
  height: 36px;
  padding-left: 8px !important;
  padding-right: 8px !important;
  border-radius: 0 5px 5px 0 !important;
  border-left: none !important;
  border-color: #797979 !important;
  background: #fff;
}

:local(.TextColorPicker > div) {
  width: 120px;
}

:local(.TextColorPicker .ColorInput) {
  border-radius: 5px !important;
}

:local(.Info) {
  font-size: 13px;
}

:local(.ColorRow) {
  display: flex;
}

:local(.ColorRow + .ColorRow) {
  margin-top: 12px;
}

:local(.ColorOption) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
}

:local(.ColorValue) {
  composes: ColorOption;
  position: relative;
  top: -9px;
  left: -12px;
}

:local(.ColorBox) {
  width: 24px;
  height: 24px;
}

:local(.shouldShowOutline) {
  border: 1px solid #bdbdbd;
}

:local(.error) {
  margin: 0;
  font-size: 13px;
  color: #db4437;
}

:local(.Upload) {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

/* :local(.Upload) { */
/*   width: 134px; */
/*   height: 36px; */
/*   background: #ffffff; */
/*   border: solid 1px #2e7de1; */
/*   border-radius: 5px; */
/* } */
