:local(.tabs) [role=tablist]{
	margin: 0;
	border: 0;
}

:local(.tabs) [role=tab]{
	background-color: #f4f4f4;
  	border: solid 1px #bdbdbd;
  	padding: 10px 20px;
  	font-size: 17px;
  	color: #797979;
  	font-weight: bold;
  	text-transform: uppercase;
  	border-left-color: rgba(0,0,0,0); 
  	border-right-color: rgba(0,0,0,0); 
}
:local(.tabs) [role=tab]:first-child{
	border-top-left-radius: 10px; 
	border-left-color: #bdbdbd;
}
:local(.tabs) [role=tab]:last-child{
	border-top-right-radius: 10px; 
	border-right-color: #bdbdbd;
}
:local(.tabs) [role=tab][aria-selected=true]{
	border-top-left-radius: 10px; 
	border-top-right-radius: 10px; 
	background: #fff;
	color: #000;
	border-color: #bdbdbd;
	border-bottom-color: #fff;
}
:local(.tabs) [role=tab][aria-selected=true]:before,
:local(.tabs) [role=tab][aria-selected=true]:after{
	content: "";
    position: absolute;
    width: 8px;
    height: 1px;
    background: #bdbdbd;
    top: -1px;
}
:local(.tabs) [role=tab][aria-selected=true]:before{
	left: -1px;
}
:local(.tabs) [role=tab][aria-selected=true]:after{
	right: -1px;
}   

:local(.tabs) [role=tab][aria-selected=true]:last-child:after,
:local(.tabs) [role=tab][aria-selected=true]:first-child:before{
	display: none;
}



:local(.tabs) [role=tabpanel]{
	background-color: #ffffff;
  border: solid 1px #bdbdbd;
  border-radius: 10px;
  border-top-left-radius: 0;
  overflow: hidden;
  -webkit-mask-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

:local(.tabAction){
	position: absolute;
	z-index: 2;
	right: 20px;
	top: 0;
}
