:local(.ModalContent) {
  max-width: none !important;
  border-radius: 3px !important;
  display: flex;
  flex-direction: column;
  width: 500px;
  min-width: 500px;
}

:local(.ModalHeader) {
  font-size: 17px;
  border-radius: 3px 3px 0 0 !important;
}

:local(.ModalBody) {
  display: flex;
  flex-direction: column;
  padding: 0;
  box-shadow: 0 1px 0 0 #bdbdbd;
  overflow-y: hidden;
  height: 490px;
}
:local(.ModalBodyCompact){
  height: 360px;
}
:local(.ModalFooter) {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  border-radius: 0 0 3px 3px !important;
  text-align: right;
}

:local(.Heading) {
  font-weight: bold;
}
:local(.deleteSkillButton){
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}
:local(.deleteSkillButton),
:local(.deleteSkillButton):active,
:local(.deleteSkillButton):hover{
  color: #db4437;
}
