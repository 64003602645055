:local(.StreamsModal) {
  position: absolute;
  top: 42% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  overflow: visible !important;
  width: 500px !important;
  border-radius: 10px !important;
  outline: none !important;
}

:local(.ModalContent) {
  border-radius: 10px !important;
}

:local(.ModalHeader) {
  font-size: 17px;
  border-radius: 10px 10px 0 0 !important;
}

:local(.ModalBody) {
  padding: 20px 20px 32px 20px;
  box-shadow: 0 1px 0 0 #bdbdbd;
}

:local(.ModalFooter) {
  padding-top: 12px;
  padding-bottom: 11px;
  padding-right: 20px;
  border-radius: 0 0 10px 10px !important;
}

:local(.Heading) {
  font-weight: bold;
}

:local(.Info) {
  margin-top: 4px;
  margin-bottom: 10px;
  font-size: 13px;
}

:local(.SaveButton) {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  font-size: 17px;
}

:local(.Weak) {
  color: #797979;
}

:local(.Dropdown > div:first-child) {
  height: 36px;
  border: 1px solid #797979;
  border-radius: 5px;
}

:local(.Option) {
  /* background: red; */
}