.ui-checkbox {
  display: inline-block;
  border: 2px solid $brand-primary;
  border-radius: 3px;
  min-width: 22px;
  min-height: 22px;
  &.value {
    background: url(../src/img/checkbox_checked.svg) no-repeat center; /* <TEMP> */
  }
  .label-text {
    position: relative;
    top: 1px;
  }
}

.label-text {
  font-size: 13px;
  text-transform: uppercase;
  color: #797979;
  font-weight: bold;
}

.label-input {
  .label-text {
    margin-bottom: 8px;
  }
  input {
    min-width: 265px;
    padding: 6px 10px;
    font-size: $font-size-base;
    font-weight: normal;
    color: $gray;
    border: 1px solid rgba(121, 121, 121, 0.9);
    border-radius: $input-border-radius;
    /* box-shadow: none; */
    @include placeholder-text-color(#bdbdbd);
    &[disabled] {
      background: #f7f7f7;
    }
  }
  &.has-error {
    input {border-color: $brand-danger}
    .label-text{
      color: $brand-danger;
    }
    .text-danger {
      text-transform: none;
      font-weight: normal;
    }
  }
  .descript {
    margin-top: 4px;
    margin-bottom: 2px;
    @extend .plain-text;  // More priority
  }
  .text-danger {
    margin-top: 8px;
  }
  &.full-width input{
    width: 100%;
  }
}

.label-checkbox {
  display: flex;
  cursor: pointer;
  align-items: center;
  .label-text {
    @extend .font-reset;
    position: relative;
    top: 2px;
    margin-left: 6px;
    font-weight: 500;
    color: #000 !important;
  }
  & + & {
    margin-top: 18px;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.36;
  }
}

.Select.ui-dropdown {
  min-width: 240px;
  width: 320px;
  .Select-placeholder {
    padding-top: 10px;
    font-weight: normal;
  }
  .Select-menu-outer > div {
    box-shadow: 2px 0 12px #888;
    > div {
      display: flex;
      align-items: center;
      height: 40px;
    }
    div:nth-child(even) {
      background: #fff;
    }
    div:hover {
      background: $brand-primary;
      color: #fff;
    }
  }
}

.label-text + .ui-dropdown {
  margin-top: 8px;
}

.icon-button {
  background: none;
  border: none;
}

.Caution {
  padding: 0 0;
}

/* Make labels and input elements stretch to fit their container. */
.stretch-form-content {
  label {
    width: 100%;
  }
  input {
    width: 100%;
  }
}

.borderless {
  border: none;
}

.save-btn {
  position: relative;
  transition: background 200ms ease-out;
  &.is-loading {
    background: #fff;
    border: 1px solid $brand-primary;
    .btn-content {
      visibility: hidden;
    }
    &:hover {
      background: #fff;
    }
    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 24px;
      margin: -12px 0 0 -12px !important;
      &:after {
        content: "";
        display: none;
      }
    }
  }
  &.btn-link {
    border: none;
  }
}

.no-button {
  &:hover {
    text-decoration: none;
  }
}

.form-hint {
  color: rgba(121, 121, 121, 0.9);
  font-size: 13px;
  font-weight: normal;
  margin: 2px 0 0;
}

.unsaved-changes {
  margin-left: 6px;
  margin-right: 6px;
  color: rgba(121, 121, 121, 0.9);
}

.form-control{
  @include placeholder-text-color(#bdbdbd);
}
