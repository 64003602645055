html{
  height: 100%;
}
body{
  height: 100%;
  background: #f7f7f7;
  font-weight: 400;
}
hr{
  border-color: #e0e0e0
}
h5 {
  font-size: 15px;
}

img:focus {
  outline: none;
}

.content-wrapper {
}

.header-pype-logo-dropdown {
  padding-top: 13px !important;
  padding-bottom: 12px !important;
}
.header-pype-logo {
  background: none repeat scroll 0 0 #fff;
  border: 1px solid #cfdbe2;
  display: inline-block;
  height: 30px;
  width: 30px;
  margin-right: 14px;
}
.header-pype-logo img {
  overflow: hidden;
  width: 100%;
}
.nav-wrapper .nav > li.active {
  background-color: rgba(255, 255, 255, 0.2);
}
.label.square {
  width: 20px;
  height: 20px;
  padding: 0;
  line-height: 20px;
}
.profile-link {
  padding-top: 13px !important;
  padding-bottom: 12px !important;
}
.profile-link .img-thumbnail {
  border: 0;
}
.text-muted {
  color: $text-muted
}
.table-striped > tbody > tr:nth-of-type(odd){
  background: #fff;
}
.table-striped > tbody > tr:nth-of-type(even){
  background: #f7f7f7;
}
.table.fixed{
  table-layout: fixed;
}
.clickable:hover{
  cursor: pointer;
}

.tab-bar{
  border-bottom: 1px solid #bcbcbc;
  padding: 10px;
  margin-bottom: 40px;
  .tab-link{
    font-size: 18px;
    font-weight: 600;
    color: #bcbcbc;
    padding: 0 10px;
    &.active{
      color: $gray-darker;
    }
    &:focus{
      text-decoration: none;
    }
  }
}

.tag {
  border: none;
  font-size: 13px;
  padding: 0px;
  max-width: 226px;
  overflow: hidden;
  text-overflow: ellipsis;

}

.panel {
  box-shadow: 0 2px 2px rgba(0,0,0,.2);
  &.panel-narrow{
    display: table;
    overflow: hidden;
    .panel-body{
      display: table-row;
      .panel-icon{
        width: 109px;
        text-align: center;
        padding-top:20px;
        padding-bottom: 20px;
        border-right: 1px solid #b6b6b6;
        .caption{
          font-size: 12px;
          margin: 0;
          margin-top: 10px;
        }
      }
      .panel-middle{
        padding-left: 20px;
        padding-right: 20px;
        border-right: 1px solid #b6b6b6;
      }
      .panel-cta{
        width: 100px;
        text-align: center;
        color: #fff;
        background: $brand-primary;
        box-shadow: -7px 0 9px rgba(0, 0, 0, 0.09);
        .badge{
          height: 30px;
          width: 30px;
          border-radius: 15px;
          padding: 0;
          line-height: 30px;
          font-size: 15px;
          font-weight: 300;
          background: $brand-danger;
        }
        p{
          font-size: 15px;
          margin: 0;
          margin-top: 10px;
          line-height: 15px;
          font-weight: 300;
        }
      }
      .panel-icon,
      .panel-middle,
      .panel-cta{
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
  .panel-body{
    .table{
      margin: 0;
      tr{
        td,
        th{
          border:0;
          border-right: 1px solid #b6b6b6;
          font-size: 15px;
          &:last-child{
            border-right: 0;
          }
        }
        th{
          border-bottom: 1px solid #b6b6b6;
          font-weight: 400;
        }
        td{
          font-weight: 300;
        }
      }
    }
  }
}


/* MISC */
.hint {
  margin-left: 5px;
}
.relative{
  position: relative !important;
}
.b-gray{
  border-color: #B6B6B8 !important;
}
.b-primary{
  border-color: $brand-primary !important;
}
.b-r{
  border-right: 1px solid;
}
.b-l{
  border-left: 1px solid;
}
.b-t{
  border-top: 1px solid;
}
.b-b{
  border-bottom: 1px solid;
}
.bg-primary{
  background-color: $brand-primary;
}

.tab-content {
  padding: 0;
  border: none;
}
.btn-link{
  color: $brand-primary;
  background: transparent;
  border:none;

  &[disabled] {
    color: $gray-light;
  }
}

.btn:active {
  box-shadow: none
}

.btn:focus {
  outline: none;
}

.btn-link:focus, .btn-link:active, .btn-link:active:focus{
  outline: none;
  text-decoration: none;
}

.btn.btn-primary {
  font-weight: 500;
}

.btn-transparent{
  background: transparent;
  border:none;
}
.btn-back {
  margin-right: 20px;
  padding: 10px 23px 10px 0;
  position: relative;
}
.btn-back::after {
  background: none repeat scroll 0 0 #cfdbe2;
  bottom: -10px;
  content: "";
  position: absolute;
  right: 0;
  top: -6px;
  width: 1px;
}

.add-button {
  margin-right: 5px;
  vertical-align: text-top;
}

.button-loading {
  background: url("../src/img/spiffygif_32x32.gif") no-repeat center;
  span {
    visibility: hidden;
  }
}

.no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// PADDINGS
.p-0{
  padding: 0 !important;
}
.p-sm{
  padding: 5px;
}
.p-10{
  padding: 10px !important;
}
.p-20{
  padding: 20px;
}
.p-30{
  padding: 30px;
}
.p-l-0{
  padding-left: 0;
}
.p-t-0{
  padding-top: 0;
}
.p-b-0{
  padding-bottom: 0;
}
.p-r-0{
  padding-right: 0;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-l-10{
  padding-left: 10px;
}
.p-r-10{
  padding-right: 10px !important;
}
.p-b-10{
  padding-bottom: 10px;
}
.p-t-10{
  padding-top: 10px;
}
.p-l-20{
  padding-left: 20px;
}
.p-r-20{
  padding-right: 20px;
}
.p-b-20{
  padding-bottom: 20px;
}
.p-t-20{
  padding-top: 20px;
}
.p-l-30{
  padding-left: 30px;
}
.p-r-30{
  padding-right: 30px;
}
.p-b-30{
  padding-bottom: 30px;
}
.p-t-30{
  padding-top: 30px;
}
// MARGINS
.m-l-a {
  margin-left: auto;
}
.m-r-a {
  margin-right: auto;
}

.m-l-0{
  margin-left: 0 !important;
}
.m-r-0{
  margin-right:0 !important;
}
.m-t-0{
  margin-top: 0 !important;
}
.m-b-0{
  margin-bottom: 0 !important;
}

.m-b-5 {
  margin-bottom: 5px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-r-5 {
  margin-right: 5px;
}

.m-l-10{
  margin-left: 10px;
}
.m-r-10{
  margin-right: 10px;
}
.m-t-6 {
  margin-top: 6px;
}
.m-t-10{
  margin-top: 10px;
}
.m-b-10{
  margin-bottom: 10px;
}

.m-l-20{
  margin-left: 20px;
}
.m-r-20{
  margin-right: 20px;
}
.m-t-20{
  margin-top: 20px;
}
.m-b-20{
  margin-bottom: 20px;
}

.m-l-30{
  margin-left: 30px;
}
.m-r-30{
  margin-right: 30px;
}
.m-t-30{
  margin-top: 30px;
}
.m-b-30{
  margin-bottom: 30px;
}

.m-l-40 {
  margin-left: 40px;
}

.no-padding{
  padding: 0 !important;
}
.no-list-padding {
  padding-left: 18px !important;
}
.no-margin{
  margin: 0 !important;
}
.no-overflow {
  overflow: hidden;
}
.no-border{
  border:0 !important;
  &.form-control{
    box-shadow: none;
  }
}
.no-background{
  background:  transparent !important;
}
.transparent-background {
  background-image: url('../src/img/checker.png');
}
.no-resize{
  resize: none;
}
.vertical-top{
  vertical-align: top;
}

/* DISPLAYS */

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}
.inline-block{
  display: inline-block !important;
}
.inline-flex{
  display: inline-flex !important;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-right {
  margin-left: auto;
}

.full-width{
  width: 100%;
}
.full-height{
  height: 100% !important;
  background-color: #fff;
}

.pypestream-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pypestream-loader > div {
  min-width: 128px;
  min-height: 128px;
  height: 128px;
  width: 128px;
  position: relative;
}

/* CATALOGUE */
.sort-alphabet {
  margin: 0;
  padding: 0;
}
.sort-alphabet .sort-letter {
  display: inline-block;
  list-style: none;
  width: 3.5%;
}
.sort-alphabet .sort-letter a {
  display: block;
  height: 20px;
  overflow: hidden;
  text-align: center;
  width: 20px;
  color: #929292;
}
.sort-alphabet .sort-letter.active a {
  background: #23b7e5;
  color: #fff;
  border-radius: 30px;
}




/* SCROLLING TABLES */
.fixedDataTableLayout_main{

  .table-header {
    .fixedDataTableCellLayout_wrap3 {
      vertical-align: bottom;
    }
  }
  img:hover {
    cursor: pointer;
  }
}


/* FORMS */
.form-group {
  input.form-control {
     color: #000;
     padding: 6px 12px;
  }
  textarea.form-control {
    color: #000;
  }
}

/* MAIN */
.main{
  padding-top: 25px;
  padding-left: 105px;
}

/* JQUERY UI OVERRIDES */
.ui-dialog{
  z-index: 99999;
}
.ui-widget{
  font-family: $font-family-sans-serif;
}
.ui-widget-header{
  background: $gray-darker;
  border: none;
  text-align: center;
  color: #fff;
  font-weight: normal;

}

/* SIGNUP FLOW */
.login-container{
  padding-bottom: 80px;
  padding-top: 40px;
  min-height: 100vh;
  margin-bottom: -79px;
}

.footer {
  height: 80px;
  padding: 30px;
  color: #999;
  font-size: 16px;
  width: 100%;
}
.footer a {
  color: #999;
}
.footer a.social-icon {
  padding: 0 5px;
}
#root > div {
  background: #fff;
}
.wrapper{
  position: relative;
  flex: 1;
  background-color: #fff;
}

.modal-title{
  font-size: 17px;
  font-weight: normal;
}
select.form-control.expand-with-to-content {
  min-width: 100%;
  width: auto;
}

.bold-text {
  font-weight: bold;
}


.navbar-brand{
    overflow: hidden;
    background: $gray-dark;
}
.navbar-default{
  border:none;
}
.wrapper-inner{
  height: 100%;
  width: 100%;
}
.top-nav-wrapper {
  margin: 0 10.8rem;
  margin-bottom: 3rem;
}
.top-nav-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.top-nav-overview {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-family: 'Open Sans', sans-serif;
  padding: 0;

  small {
    margin-left: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
    color: #7a6769;
  }

  h4 {
    font-size: 26px;
    line-height: 1.23;
  }
}
.all-pypes-btn {
  margin-left: 10.8rem;
}
.content-wrapper{
  height: 100%;
  margin-left: 10.8rem;
  margin-right: 10.8rem; 
  background: #fff;
  .content{
    height: 100%;
    .content-inner{
      height: 100%;
      display: table;
      width: 100%;
      overflow: hidden;
      & > .no-pane{
        display: table-row;
        overflow-y: auto;
        height: 100%;
        position: relative;
      }
    }
  }
}


.rounded{
  border-radius: 200px;
  overflow: hidden;
  display: inline-block;
}
.avatar-initial{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background: $brand-primary;

  h3 {
    margin: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content:center;
  }
  &.avatar-initial-small{
    width: 30px;
    height: 30px;
  }
  &.avatar-initial-large{
    width: 120px;
    height: 120px;
    & > h3{
      font-size: 50px;
    }
  }
  & > h3{
    font-size: 19px;
    font-weight: normal;
  }
}
.hover:hover{
  background: rgba(45, 124, 225, 0.08);
}
.scrollable{
  overflow-y: auto;
}

.rounded-rect {
  padding: 15px 20px;
  border-radius: 63px;
  height: 70px;
  border: 1px solid $brand-primary;
  display: table;
  width: 100%;
  .rounded-rect-inner{
    display: table-row;
  }
  .left{
    margin-left: 9px;
    display: table-cell;
    text-align: center;
  }
  .right{
    padding-left: 10px;
    display: table-cell;
    vertical-align: middle;
  }
}
.copyright{
  color: #999;
  font-size: 13px;
  a{
    color: #999;
  }
}
.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td{
  border:none;
  text-overflow: ellipsis;
  overflow: auto;
  overflow-x: hidden;
}
.table th{
  text-transform: uppercase;
  color: #999999;
  font-size: 13px;
  font-weight: 500;
}


.nav > li > a{
  padding: 4px 15px;
}
.nav > li > a:hover, .nav > li > a:focus{
  color: #fff;
}



.progress-bar-wrapper {
  width: 314px;
  height: 36px;
  border-radius: 2px;
  border: solid 1px #d6d6d6;
  position: relative;
  overflow: hidden;
}

.progress-bar-wrapper .progress-bar{
  position: absolute;
  left: 0;
  width: 0;
  top: 0;
  bottom: 0;
  background: $brand-primary;
  z-index: 0;
  transition: width .3s ease;
  -webkit-transition: width .3s ease;
}

.progress-bar-wrapper .progress-value{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: #000;
  line-height: 36px;
  text-align: center;
  z-index: 1;
}

input[type="file"].form-control {
  height: auto;
  max-height: 33px;
  min-height: 33px;
  font-size: 13px;
  padding-top: 6px;
}

// .btn.disabled, .btn[disabled], .btn.disabled:hover, .btn[disabled]:hover {
//   background-color: #999999;
//   border-color: #999999;
// }

.app-tooltip {
  cursor: pointer;
  color: #000;
  &:hover {
    color: #000;
  }
}

.extra-tooltip {
 &:hover {
   visibility: visible !important;
   opacity: 1 !important;
 }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.not-found {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100vh;

  img {
    margin: 0 auto;
  }

  h5 {
    line-height: 19px;
  }
}

.flex-row{
  display: flex;
  // flex-wrap: wrap; // Safari issue: PYPE-5281
}
.flex-col{
  display: flex;
  flex-direction: column;
}

.embedPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 72px; 
  height: 72px;
  border: 1px solid #bbb;
  border-radius: 4px;

  &--listpicker {
      width: 64px;
      height: 10px;
      background-color: #ddd;
      margin: 0 4px;

      &:not(:last-of-type) {
          margin-bottom: 4px;
      }
  }

  &--applePay {
    color: black;
    width: 32px;
  }
}