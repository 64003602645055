/*
 * Campaigns
 */

.campaign-container {
  .stats {
    border-radius: 3px;
    border-collapse: separate;
    border: 1px solid #2e7de1;

    td, th {
      text-align: center;
      padding: 6px;
    }

    th {
      background: #2e7de1;
      color: #fff;
      font-weight: normal;
    }

    td {
      font-size: 17px;
      width: 50%;
    }

    td:not(:last-of-type) {
      border-right: 1px solid #2e7de1;
    }

    tbody {
      tr:first-child td {
        padding-bottom: 0;
      }

      tr:last-child td {
        padding-top: 0;
        color: rgba(50, 51, 58, 0.4);
      }
    }
  }

  .campaign-body {
    max-width: 520px;
    overflow: auto;
  }
}

.campaign-stats-table{
  border-collapse: separate;
  border-spacing: 0;
  th{
    background: $brand-primary;
    color: #fff;
    font-size: 13px;
  }
   tr th,
   tr td {
    border-right: 1px solid $brand-primary !important;
    border-bottom: 1px solid $brand-primary !important;
  }
   tr th:first-child,
   tr td:first-child {
    border-left: 1px solid $brand-primary;
  }
  tr:first-child th:first-child {
    border-top-left-radius: 3px;
  }

  /* top-right border-radius */
  tr:first-child th:last-child {
    border-top-right-radius: 3px;
  }

  /* bottom-left border-radius */
  tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
  }

  /* bottom-right border-radius */
  tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
  }

  td{
    vertical-align: middle;
    width: 50%;
  }
  .perc{
    opacity: .5;
    font-size: 17px;
  }
}
