:local(.table){
	background-color: #ffffff;
  border: solid 1px #bdbdbd;
  border-radius: 10px;
  overflow: hidden;
  -webkit-mask-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

:local(.tableHeader){
	font-size: 17px;
  font-weight: bold;
  color: #000000;
  padding:15px;
  text-transform: uppercase;
}