.agent-signup {

  position: relative;
  margin: 0 auto;
  background: #fff;

  .main-wrapper {
    padding: 40px 80px 80px;
    border-top: 1px solid #aaa;
    box-shadow: 0 4px 16px #d8d8d8;
  }

  h3 {
    /* height: 24px; */
    margin-bottom: 16px;
    color: #32333a;
  }

  .company-logo {
    position: relative;
    top: 10px;
    min-width: 104px;
    height: 104px;
  }

  .heading-light-cap {
    color: #474747 !important;
    font-weight: bold;
    letter-spacing: 0.5px;
  }

  .column-1, .column-2 {
    position: relative;
  }

  .column-1 {
    flex: 1;
    padding-right: 20px;
    /* h3, p { */
    /*   padding-left: 120px; */
    /* } */
    h3 {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .column-2 {
    flex: 1;
    .one-step {
      font-size: 20px;
    }
    h3 {
      font-family: "BerninoSans-CondensedBold";
      font-size: 35px;
      font-weight: normal;
    }
    .label-input input {
      width: 265px;
    }
    .label-input .hint{
      color: rgba(121, 121, 121, 0.9);
      font-size: 13px;
      font-weight: normal;
      max-width: 287px;
      margin: 2px 0 0;
    }
  }

  /* .header { */
  /*   display: flex; */
  /*   align-items: baseline; */
  /*   .column-1 { */
  /*     h3 { */
  /*       font-size: 22px; */
  /*       font-weight: bold; */
  /*     } */
  /*   } */
  /*   .column-2 { */
  /*     h3 { */
  /*       font-family: "BerninoSans-CondensedBold"; */
  /*       font-size: 35px; */
  /*       font-weight: normal; */
  /*     } */
  /*   } */
  /* } */

  .header-content-wrapper {
    display: flex;
    .inner-content {
      padding-left: 16px;
    }
  }

  .content {
    display: flex;
    .column-2 {
      > section {
        margin: 32px 0;
        &:first-child {margin-top: 0;}
      }
      .agent-info-section {
        section {margin: 8px 0;}
      }
    }
  }

  .label-input {
    display: block;
  }

  .label-input + .label-input {
    margin-top: 20px;
  }

  .btn-primary {
    margin-top: 24px;
  }

}