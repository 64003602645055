.agent-status-toggle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  border-bottom: 1px solid #d9d9d9;
  color: #000;
  font-size: 13px;
  .context-menu {
    .agent-status-status {
      padding: 6px;
      border: 1px solid transparent;
      &:hover {
        border-radius: 3px;
        border-color: #979797;
      }
    }
  }
  &.isSubmitting .context-menu .agent-status-status {
    opacity: 0.4;
  }
  .diamond {
    margin-left: -8px !important;
  }
  .status-indicator {
    margin-top: -2px;
  }
  .arrow-up {
    width: 0;
	  height: 0;
	  border-left: 4px solid transparent;
	  border-right: 4px solid transparent;
	  border-bottom: 6px solid #828282;
    margin-left: 4px;
  }
  .arrow-down {
    width: 0;
	  height: 0;
	  border-left: 4px solid transparent;
	  border-right: 4px solid transparent;
	  border-top: 6px solid #828282;
    margin-left: 4px;
  }
  .app-tooltip{
    display: flex !important;
    width: 100%;
    height: 100%;
    justify-content: center;
    & > div{
      width: 100%;
    }
  }
}

.agent-status-status {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding:5px;
  &.is-enabled {
    cursor: default;
    .icon {
      position: absolute;
      right: 4px;
    }
  }
}

.agent-status-current-status:hover {
  background: #fff;
}

.status-indicator{
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  margin-right: 6px;
  flex: none;
  &.online {
    background-image: url(../src/img/icon-online.svg);
  }
  &.busy {
    background-image: url(../src/img/icon-busy.svg);
  }
  &.away {
    background-image: url(../src/img/icon-away.svg);
  }
  &.offline {
    background-image: url(../src/img/icon-offline.svg);
  }
}