:local(.row){
  padding: 8px 20px;
  box-shadow: inset 0 -0.5px 0 0 #bdbdbd;
  display: flex;
  align-items: center;
}
:local(.row) p{
  flex: 1;
}
:local(.unselected){
  color: #797979;
}
