:local(.selectWrapper){
	flex: 1;
}
:local(.selectWrapper) .Select-menu-outer {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #d6d6d6;
  margin-top: 0;
}
:local(.selectWrapper) .Select-menu-outer .Select-option{
	padding: 10px;
	box-shadow: inset 0 -1px 0 0 #e5e5e5;
	color: #000;
}
:local(.selectWrapper) .Select-menu-outer .Select-option:hover{
 box-shadow: inset 2px 0 0 0 #2e7de1, inset 0 -1px 0 0 #e5e5e5;	
}
:local(.selectWrapper) .Select-menu-outer .Select-option.is-selected{
	position: relative;
}
:local(.selectWrapper) .Select-menu-outer .Select-option.is-selected:after{
	content: "";
	width: 14px;
	height: 14px;
	background: url(../../../../img/icon-check-small.svg) no-repeat;
	background-size: contain;
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -5px;
}
:local(.selectWrapper) .Select-menu-outer .Select-option.is-disabled{
	color: #bdbdbd;
	background-color: #f4f4f4;
}
:local(.selectWrapper) .is-open .Select-control{
 border-color: #2e7de1;
 border-radius: 5px;
}
:local(.selectWrapper) .is-open .Select-arrow{
	transform:rotateZ(-180deg);
}
:local(.selectWrapper) .Select-value{
	color: #000;
}
:local(.selectWrapper) .is-disabled .Select-control{
	border-color: #CACACA;
} 
:local(.selectWrapper) .is-disabled .Select-control .Select-arrow{
	border-top-color: #CACACA;
}
:local(.selectWrapper) .is-disabled .Select-control .Select-placeholder{
	color: #CACACA;
}
:local(.timeSelect){
	width: 100%;
	display: inline-block;
	vertical-align: middle;
}
:local(.toTime).is-focused .Select-placeholder{
	color: #2e7de1;
}

:local(.selectRow){
	display: flex;
	align-items: center;
	flex-direction: row;
}

:local(.selectRow) .fade{
	opacity: .4;
}
