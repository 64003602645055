:local(.SkillList) {
  display: flex;
  flex-direction: column;
  min-height: 0; /* FF fix */
  position: relative;
  width: 100%;
  height: calc(100% - 145px);
}

:local(.Content) {
  overflow-y: auto;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.15);
}

:local(.Scrollable) {
  max-height: 440px;
}

:local(.CreateSkill) {
  height: 30px;
  margin: 16px 30px;
  cursor: pointer;
  div {
    img {
      width: 30px;
      height: 30px;
    }
  }

  div:hover {
    img {
      background: url(../../../../img/icon-add-hover.svg) no-repeat;
    }

    span {
      color: #1b4b87;
      text-decoration: underline;
    }
  }
}

:local(.Text) {
  margin-top: 2px;
  margin-left: 12px;
  color: #2e7de1;
}
