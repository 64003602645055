$userInfoPanelWidth: 380px;

.scrollable-content {
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 16px;
  z-index: -1;
}

.user-info-panel {

  z-index: 4;
  position: absolute;
  top: 46px;
  bottom: 0;
  right: 0;
  width: $userInfoPanelWidth;
  min-width: $userInfoPanelWidth;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 2px 16px #aaa;
  .crm-data-spinner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .switch {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 50px;
    background: #fff;
    a {
      position: relative;
      padding-bottom: 4px;
      @extend .heading-light-cap;
      font-weight: normal;
      &.active:before {
        content: "";
        position: absolute;
        width: 100%;
        bottom: -2px;
        border-bottom: 2px solid $brand-primary;
      }
      &:hover {
        text-decoration: none;
      }
    }
    a + a {
      margin-left: 16px;
    }
  }

  .profile-bubble {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 32px;
  }
  .events-tab {
    padding: 16px;
  }
  .user-info-header {
    padding-top: 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    .user-info-wrapper{
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .old-user-info-wrapper{
      padding: 16px;
    }
    .chevron {
      position: relative;
      top: -1px;
      margin-left: 6px;
    }
    .sub-header-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .avatar-icon {
      position: relative;
      border-radius: 32px;
      width: 52px;
      height: 52px;
    }
    h4 {
      position: relative;
      top: 8px;
      font-size: 20px;
    }
    .open-user-data {
      position: relative;
      top: -4px;
      .pointer-arrow {
        margin-left: 2px;
      }
    }
    .user-info-subsection {
      margin-bottom: 40px;

      ul {
        list-style-type: none;
        padding: 0;
        
        li {
          font-family: 'Open Sans';
          display: flex;
          justify-content: space-between;
          margin-top: 16px;

          p {
            margin: 0;
          }

          .--title {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;

            &-extra {
              font-size: 16px;
              font-weight: 600;
              line-height: 1.5;
              text-transform: capitalize;
            }

            &-null {
              color: #7a6769;
            }
          }

          .--body {
            font-size: 16px;
            line-height: 1.5;
            word-break: break-all;
          }
        }
      }
    }
    .stream-list {
      margin-top: 10px;
      margin-bottom: 10px;
      /* background: #e6e6e6; */
      /* padding: 12px 16px; */
      /* border-radius: 8px; */
    }
    .stream-item {
      display: inline-block;
      &:not(:last-child):after {
        content: ", ";
        margin-right: 4px;
      }
    }
    .user-data {
      /* transition: transform 320ms ease-out; */
      /* transform: scaleY(0); */
    }
    &.is-profile-open {
      /* .user-data { */
      /*   transform: scaleY(1); */
      /* } */
    }
  }

  .customer-tab {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    bottom: 0;
    width: 100%;
    .content-section {
      flex: 1;
    }
  }

  .user-chat-history-label {
    margin: 12px 0;
    text-align: center;
    @extend .heading-light-cap;
  }

  .content-section {
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    overflow-y: auto;
  }

  .chat-list {
    flex: 1;
    width: 100%;
    padding: 0 16px;
    overflow-y: auto;
    margin-top: 20px;
  }

  .chat-item {
    margin: 12px 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    &:first-child {
      margin-top: 0;
    }
  }

  .chat-message {
    z-index: 0;
    margin-left: 6px;
    margin-bottom: 0;
    margin-top: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #797979;
    color: #fff;
    border-radius: 18px;
    word-break: break-word;
    white-space: pre-line;
    &.received:before {
      border-right-color: #797979;
    }
    &.sent:before {
      border-left-color: #797979;
    }
    .file-caption {
      margin-bottom: 0;
    }
    img{
      max-width: 220px;
    }
  }

  .chat-message-container.sent + .received,
  .chat-message-container.received + .sent {
    margin-top: 6px;
  }

  .bubble-tip {
    display: none;
  }

  .is-last-sent {
    .bubble-tip {display: inline-block;}
  }

  .chat-item-body {
    padding: 12px 12px 16px 12px;
    .view-history {
      font-size: 13px;
      margin-left: 56px;
    }
    .profile-bubble {
      z-index: 1;
      border-radius: 32px;
    }
    .first-chat-message {
      display: flex;
      align-items: flex-end;
      a {
        color: #fff;
      }
    }
  }

  .chat-item-title, .chat-item-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 12px 8px;
  }

  .chat-item-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .chat-item-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    img {
      width: 18px;
      height: 18px;
    }

    .agent-name {
      margin-left: 10px;

      &:hover {
        text-decoration: underline;
      }
    }

    .n-tags {
      position: relative;
      top: 4px;
      left: -2px;
      color: $brand-primary;
      font-size: 8px;
    }
    .icon-headset {
      position: relative;
      top: -1px;
    }
    .icon-comment {
      margin-left: 6px;
    }
    .tooltips {
      display: flex;

    }
  }

  .chat-item.is-current {
    border-color: $brand-primary;
    .chat-item-title, .chat-item-footer {
      background: rgba(45, 124, 225, 0.07);
      border-bottom-color: $brand-primary;
      border-top-color: $brand-primary;
    }
  }

  .chat-history {
    display: flex;
    flex-direction: column;
    background: #fff;
    .chat-item {
      margin-bottom: 0;
      border-radius: 0;
      border-left: none;
      border-right: none;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
    }
    .repr-chat-item {
      .chat-message.received:after {
        background: #fff;
      }
    }
    .chat-message-container {
      &:first-child {
        margin-top: 4px;
      }
      display: flex;
      align-items: flex-end;
      height: auto;
      /* padding: 4px 12px; */
      padding: 2px 12px;
      &.sent {
        justify-content: flex-end;
      }
    }
    .profile-bubble {
      z-index: 1;
    }
    .chat-message {
      margin-top: 0;
      &.sent {
        margin-right: 8px;
        border: solid 1px #bdbdbd;
        background: transparent;
        /* background: #fff; */
        color: #797979;
        /* &:before { */
        /*   border-left-color: #fff; */
        /* } */
        &:after {
          background: #fff;
          &.sent:before {
            /* border-left-color: #b0b0b0; */
            /* border-left-color: #fff; */
          }
          &.sent:after {
            /* background: #f4f4f4; */
          }
        }
      }
      &.received {
        &:after {
          background: #fff;
        }
        a{
          color: #fff;
        }
      }
    }
    .close-history {
      position: relative;
      top: 12px;
      height: 0;
      margin: 0;
    }
    .nav-top {
      position: absolute;
      bottom: 0;
      .simple-rect-button {
        pointer-events: none;
        opacity: 0;
        transition: opacity 200ms ease-out;
      }
      &.show {
        .simple-rect-button {
          pointer-events: auto;
          opacity: 1;
        }
      }
    }
  }

  .chat-history-list {
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 56px;
    .chat-message.sent {
      &:before, &:after {
        background: none;
        border: none;
      }
    }
    .chat-message-container.received {
      .profile-bubble {
        visibility: hidden;
      }
      .chat-message:before {
        border: none;
      }
    }
    .received.is-last-received {
      .profile-bubble {
        visibility: visible;
      }
      .chat-message:before {
        border-right: 20px solid #797979;
      }
    }
  }

  .simple-rect-button-wrapper {
    z-index: 1;
    width: 100%;
    margin: 12px 0;
  }

  .simple-rect-button {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 18px;
    text-align: center;
    width: auto;
    height: 32px;
    background-color: #ffffff;
    color: #797979;
    border: solid 1px #797979;
    border-radius: 3px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  }

}

.chat-message {
  position: relative;
  .bubble-tip {
    position: absolute;
    bottom: -5px;
    right: -11px;
  }
}

.simple-tags-modal {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

@media (min-width: 1200px) {

  .user-info-is-open {
    .content-wrapper {
      width: 140rem !important;
    }
    .panes .pane.pane-detail{
      width: 100%;
    }
    .panes .pane.pane-list{
      display: flex;
      &.history-filters-is-open{
        display: flex;
      }
    }
  }
  .user-info-panel {
    position: static;
    top: 0;
    right: 0;
    min-width: $userInfoPanelWidth;
    box-shadow: none;
    border-left: 1px solid #bdbdbd;

    &--tab {
      text-transform: capitalize !important;
    }
  }

}
