// input
//   &[type=email], &[type=text], &[type=password], &[type=url], &[type=number]
//     font-size: 15px
//     padding: $padding-base-vertical $padding-small-horizontal
//     border: 1px solid $input-border
//     border-radius: $input-border-radius
//     box-shadow: none
//     @include placeholder-text-color(#bdbdbd)

// input
//   &[type=number]
//     width: auto

.input-group-addon {
  font-size: 17px; }

.input-inline-group {
  display: inline-block;
  &>input {
    border-radius: 0; }
  &>* {
    font-size: 17px;
    display: inline-block;
    vertical-align: middle;
    padding: $padding-base-vertical $padding-large-horizontal;
    border: none;
    border: 1px solid $input-border;
    &:first-child {
      border-right: none;
      border-radius: $input-border-radius 0 0 $input-border-radius; }
    &:last-child {
      border-left: 1px solid $input-border;
      border-radius: 0 $input-border-radius $input-border-radius 0; } } }

.control-label {
  font-size: 13px;
  &>.hint {
    text-transform: none;
    font-weight: normal; } }

.has-error .form-control {
  border-color: $brand-danger; }

.form-control {
  font-weight: normal;
  &:focus {
    border: 1px solid rgba(121, 121, 121, 0.9);
    box-shadow: none; } }

.has-error .hint {
  color: $state-danger-text; }

.text-danger.small {
  margin-top: 5px; }

// Chrome rendering of placeholder text renders heavy and looks awful without this rule.
input, textarea, .Select-placeholder {
  -webkit-font-smoothing: antialiased; }
