.responsesTab-search {
    margin: 0 16px 16px 16px;
}

.responsesTab-dropdown {
    
    cursor: pointer;
    margin-top: 16px;

    &-content {

        .embedInformation { 
            padding: 0;
            margin: 0;
            font-size: 16px;
            font-family: 'Open Sans';

            button {
                margin-top: 8px;
            }

            &--title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                h5 {
                    margin: 0;
                    padding: 0;
                    font-family: 'Open Sans';
                    font-weight: 600;
                    line-height: 1.5;
                    font-size: 16px;
                }
            }

            img {
                cursor: pointer;
            }
        }

        width: 100%;

        &--embed {
            display: flex;
            flex-direction: row;
            margin: 0;
            margin-bottom: 16px;
    
            .embedPreview {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 72px; 
                height: 72px;
                border: 1px solid #bbb;
                border-radius: 4px;
    
                &--listpicker {
                    width: 64px;
                    height: 10px;
                    background-color: #ddd;
                    margin: 0 4px;
    
                    &:not(:last-of-type) {
                        margin-bottom: 4px;
                    }
                }

                &--applePay {
                    color: black;
                    width: 32px;
                }
            }
        
            h5 {
                margin: 0;
                font-size: 16px;
                font-weight: 600;
                line-height: 1.5;
                font-family: 'Open Sans';
            }
    
            p {
                font-family: 'Open Sans';
                font-size: 14px;
                font-style: italic;
                line-height: 1.43;
                color: #7a6769;
                margin: 0;
                margin-bottom: 4px;
            }
    
            .embedInformation { 
                display: flex;
                padding: 0;
                margin: 0;
    
                &--title {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
    
                img {
                    cursor: pointer;
                }
            }
        }
    }
}