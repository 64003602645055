.EndChatButton {}

:local(.Button) {
  width: 72px;
  height: 26px;
  font-size: 13px;
  border: none;
  border-radius: 5px;
  line-height: 13px;
}
:local(.Button):focus{
  outline: none;
}
:local(.ToggleButton) {
  position: absolute;
  top: 8px;
  right: 7px;
  color: #fff;
  background: #db4437;
  border: 1px solid #bdbdbd; 
}

:local(.Popup) {
  cursor: default;
  z-index: 2000;
  position: absolute;
  top: 43px;
  right: 10px;
  width: 290px;
  height: 78px;
  padding: 10px;
  font-size: 15px;
  color: #000;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

:local(.Buttons) {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

:local(.Cancel) {
  width: 60px;
  color: #797979;
  background: #fff;
  border: 1px solid #797979;
  transition: opacity 240ms ease-out;
}

:local(.Submit) {
  width: 67px;
  margin-left: 10px;
  color: #fff;
  background: #2e7de1;
  transition: opacity 240ms ease-out;
}

:local(.Cancel[disabled]) {
  opacity: 0.3;
}

:local(.Submit[disabled]) {
  opacity: 0.3;
}

:local(.Arrow) {
  position: absolute;
  top: -6px;
  right: 26px;
  width: 16px;
  height: 16px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  transform: rotate(45deg);
}

:local(.FixedMask) {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background: #000; */
  /* opacity: 0.26; */
}

:local(.BackMask) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 3px;
}

:local(.Error) {
  font-size: 11px;
  color: #db4437;
}