.ReactModal__Overlay {
  .notifications-tc {
    top: 0vh !important;
  }
}

.modal-header {
  background-color: #f4f4f4;
  text-align: center;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1; 
  .close {
    opacity: 0.5;
    margin: 0;
  }
  .close:hover {
    outline: none;
    opacity: 0.7;
  }
}

.modal-content {
  max-height: 95vh;
  border-radius: 3px;
  border: 0;
  box-shadow: none;

  a:hover {
    text-decoration: none;
  }
}

.modal-body {
  overflow-y: visible;
  max-height: calc(95vh - 118px);
  word-wrap: break-word;
  textarea {
    padding: 0;
  }

  hr {
    margin-left: -15px;
    margin-right: -15px;
  }

  .stream-select {

    .is-open {
      box-shadow: 0 10px 35px 3px rgba(0, 0, 0, 0.27);
    }

    .Select-control {
      height: 64px;
      padding: 0;
      border: solid 1px #d6d6d6;
      border-radius: 2px;

      .Select-placeholder {
        padding: 20px;

        .select-option {
          padding: 10px 20px;
        }
      }
      .Select-input {
        padding: 10px 20px;
      }

      .Select-value {
        padding: 10px 20px;
        margin-right: 45px;

        .current-select-indicator {
          width: 6px;
          height: 6px;
          background-color: #2e7de1;
          border-radius: 9999px;
          position: absolute;
          top: 16px;
          left: 7px;
        }
      }
    }

    .Select-arrow {
      top: 30px;
      right: 15px;
    }

    .Select-menu-outer {
      box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.27);
      border-radius: 2px;

      &:before {
        content: " ";
        display: block;
        position: absolute;
        height: 100%;
        width: 10px;
        left: 0px;
        top: 0;
      }

      .Select-option {
        border: solid 1px #d6d6d6;
        height: 64px;
        padding: 10px 20px;
        //color: #000;
      }

      .is-selected {
        display: none;
      }
    }
  }

  h5 {
    color: #999999;
    font-weight: bold;
  }

  .required-or-not {
    font-size: 13px;
    color: #999999;
  }

  textarea {
    color: #000;
    padding: 5px;
    resize: none;
  }

  textarea.no-border {
    padding: 0;
  }

  table {
    tr {
      border-bottom: 1px solid #E6E6E6;
      font-size: 15px;

      td {
        padding: 8px 0;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;

        label {
          margin: 0;
          font-weight: 700;
        }
        button {
          font-size: 15px;
        }
        .btn-input {
          color: #000;
          font-weight: 500;
          width: 100%;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .btn-default {
          color: rgba(121, 121, 121, 0.9);
          border-color: rgba(121, 121, 121, 0.9);
        }
        input {
          outline: none;
          border: none;
          font-size: 15px;
          display: inline;
          width: 100%;
          height: auto;
          padding: 5px;
        }

        input:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
    }
    tr:hover {
      background-color: #eff5fd;
      cursor: pointer;
    }
    tr:hover > td > input {
      background-color: #eff5fd;
    }
  }

  .info {
    tr:hover {
      background: none;
      cursor: auto;
    }
  }

  .form-group.form-group-in-row {
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 1px solid #e5e5e5;

    label {
      float: left;
      font-weight: normal;
      width: 25%;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 15px;
      margin: 0;
    }

    .form-control-in-row {
      margin-left: 25%;
    }

    &.select-in-row {
      .Select-control, .Select-placeholder, .Select-value {
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 0;
        height: auto;
      }

      .Select-arrow {
        top: 20px;
        right: 15px;
      }

      .Select-menu-outer {
        margin-top: 1px;
        border-radius: 0;

        //  position: relative;

        &:before {
          content: " ";
          display: block;
          position: absolute;
          height: 100%;
          width: 10px;
          left: 0px;
          background: transparent;
          top: 0;
          box-shadow: -5px 0 7px -4px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}

.modal-footer{
  border:none;
  padding-top:0;
  .tip {
    font-size: 12px;
  }
}

.modal-body .modal-footer {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.ReactModal__Content{
  border: 0 !important;
  padding: 0 !important;
  box-shadow: 0 13px 52px rgba(0,0,0,.27);
  -webkit-box-shadow: 0 13px 52px rgba(0,0,0,.27);
}
.ReactModal__Overlay{
  z-index: 998;
  background: rgba(0,0,0,0.11) !important;
}

.no-padding-modal .modal-body {
  padding-top: 0px;

  textarea.form-control {
    padding: 0;
  }

}

/*
  <TODO>
  This file is for reusable modal styles, not specific ones. Move this to _admin.scss
*/
.edit-tags-modal {
  .modal-content {
    max-width: 700px;
  }

  .modal-body {
    padding: 30px;
    overflow-y: scroll;

    a:hover {
      text-decoration: underline;
    }

    h5 {
      color: #000000;
      margin: 0;
    }
    hr {
      margin: 10px 0 0 0;
    }
    section:first-child {
      margin-top: 0;
    }
    section {
      margin: 40px 0 28px;

      section{
        margin: 20px 0;
      }
    }
    table {

      th, td {
        padding-left: 0;
        display: table-cell;
      }

      thead > tr {
        border: none;
      }

      thead > tr:hover {
        background: none;
        cursor: auto;
      }
    }
  }
}

.force-app-modal-styles {
  /*
    Unidiomatic css important rules, but necessary to override
    react-modal's inlined styles.
  */
  position: absolute;
  top: 42% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  overflow: visible !important;
}

.simple-modal {
  @extend .force-app-modal-styles;
  width: 424px !important;
  .modal-body {
    padding: 30px;
  }
}

.modal-footer {
  .unsaved-changes {
    margin-right: 16px;
    color: $gray-light;
  }
}
