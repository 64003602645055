:local(.SendButton) {
  align-self: center;
  width: 62px;
  height: 26px;
  margin-right: 5px;
  padding: 0;
  border: none;
  color: #fff;
  background-color: #2e7de1;
  border-radius: 100px;
}

:local(.Text) {
  position: relative;
  top: 1px;
}

:local(.isDisabled) {
  opacity: 0.4;
}
