.react-tabs {
  [role=tablist] {
    color: #a7a9ac;
    position: relative;
    z-index: 1; }
  [role=tab][aria-selected=true] {
    color: #2e7de1; } }

.react-select {
  display: inline-block;
  height: 36px;
  &.short {
    width: 212px; }
  .Select-control {
    line-height: 1; } }
