:local(.WidgetItem:first-child) {
  border-top: 1px solid #bdbdbd;
}
:local(.WidgetItem.isEditing:first-child) {
  border-top: 1px solid rgba(189, 189, 189, 0.3);
}

:local(.WidgetItem) {
  border-bottom: 1px solid #bdbdbd;
}

:local(.WidgetItem.isEditing) {
  border-bottom: 1px solid rgba(189, 189, 189, 0.3);
}

:local(.WidgetItem.isEditMode) {
  border-bottom: 1px solid rgba(189, 189, 189, 0.3);
}

:local(.Steps) {}

:local(.isOpen .Steps) {}

:local(.keepOpen .Panel) {
  cursor: default;
}

:local(.Panel) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 30px;
  cursor: pointer;
}

:local(.Edit) {
  cursor: pointer;
  margin-right: 12px;
  width: 30px;
  height: 30px;
}

:local(.Toggle) {
  /* cursor: pointer; */
  transform: rotate(180deg);
}

:local(.ToggleActive) {
  transform: rotate(0) !important;
}

:local(.Config:first-child) {
  border-top: 1px solid #bdbdbd;
}

:local(.Config:last-child) {
  border-bottom: none;
}

:local(.Config) {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 60px;
  min-height: 60px;
  background: #fff;
  border-bottom: 1px solid #bdbdbd;
}

:local(.BulletedConfig) {
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

:local(.BulletedConfig ul) {
  margin: 0;
  padding: 0;
}

:local(.BulletedConfig li) {
  margin: 0;
  padding: 0;
}

:local(.ConfigDescriptor) {
  margin-left: -4px !important;
}

:local(.ConfigDescriptor li) {
  color: #000;
  list-style-type: none;
}

:local(.Bullet) {
  /* position: absolute; */
}

:local(.Bullet li) {
  color: #000;
  list-style-type: default;
  margin-left: 70px;
  font-size: 13px;
  position: relative;
  top: 1px;
}

:local(.Action) {
  position: relative;
  cursor: pointer;
  color: #2e7de1;
}

:local(.ScriptConfigAction) {
  cursor: pointer;
  color: #2e7de1;
  /* color: #797979; */
}

:local(.Weak) {
  margin-left: 4px;
  color: #797979;
}

:local(.AppId) {
  font-size: 13px;
  color: #797979;
}

:local(.shouldMask .Panel) {
  cursor: default;
  opacity: 0.3;
}

:local(.isEditing .Panel) {
  cursor: default;
  opacity: 0.3;
  border-bottom: 1px solid #bdbdbd;
}
