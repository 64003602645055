:local(.wrapper){
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}
:local(.anonSession){
    flex: 2;
    position: relative;
}
:local(.anonInner) {
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 11px;
    color: #797979;
}