/* Intercom circle */
#intercom-container .intercom-launcher{
    left: 20px;
    right: auto;
    visibility: visible !important;
    .intercom-launcher-button {
        border: 0;
    }

    .intercom-launcher-hovercard {
        transform: translate(285px, 0px) scale(1, 1) !important;
    }

    .intercom-launcher-hovercard::before {
        left: 21px;
    }

    .intercom-launcher-hovercard::after {
        left: 21px;
    }
}

.notification.notification-visible{
    opacity: 1 !important;
}