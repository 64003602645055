:local(.tooltip) {
  display: inherit !important;
  padding: 10px !important;
  border-radius: 0 !important;
  text-transform: none !important;
  font-weight: normal !important;
  text-align: left !important;
  pointer-events: visible !important;
}

:local(.hoverContent) {
  display: inline-block;
 }

:local(.informationIcon) {
  width: 12px;
  height: 12px;
  margin-top: -2px;
  cursor: pointer;
}