:local(.box){
	border-radius: 10px;
  background-color: #ffffff;
  border: solid 1px #bdbdbd;
  overflow: hidden;
}
:local(.box) h6{
	color: rgba(121, 121, 121, 0.9);
}
:local(.box).red-box{
	border-color: #db4437;
}
:local(.box).red-box h1,
:local(.box).red-box h6,
:local(.box).red-box span{
	color: #db4437;
}
:local(.boxContent){
	padding: 15px;
}
:local(.boxFooter){
	padding: 15px;
 background-color: #eff5fd;
}
:local(.boxFooter) h4{
	margin-top: 5px !important;
}
:local(.hint){
	color: #797979;
}
:local(.separator){
	border-right: 1px solid #bdbdbd;
}
:local(.filters) a.disabled{
	color: rgba(121, 121, 121, 0.9);
	opacity: 1;
}

:local(.dropdownItems) {
	display: flex;
	align-items: center;
}
:local(.dropdownText) {
	margin-right: 10px;
	color: rgba(121, 121, 121, 0.9);
}
:local(.dropdownContent) {
	min-width: 250px;
	width: 250px;
}
:local(.dropdownContent) .react-select__value-container {
	white-space: nowrap;
  overflow: hidden;
	text-overflow: ellipsis;
	display: block;
  text-transform: capitalize;
  padding: 4px 10px;
}
:local(.dropdownContent) .react-select__value-container > div{
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}
:local(.dropdownContent) .react-select__menu {
	z-index: 6 !important;
	margin-bottom: 7px !important;
	margin-top: 7px !important;
}
:local(.dropdownContent) .react-select__menu-list {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
:local(.dropdownOption) {
	max-width: 190px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	background: none;
}
:local(.dropdownLabel){
	max-width: 136px;
	height: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-family: Roboto;
	font-size: 15px;
	text-transform: capitalize;
	color:#000000;
	margin-top: 5px;
	font-weight: normal;
	background: none !important;
}
:local(.optionList):first-child{
	border-bottom: #cccccc solid 1.5px;
	background: none !important;
}
:local(.optionList) .ui-checkbox {
	position: relative;
	margin-right: 6px;
	bottom: 4px;
}
:local(.optionList) .react-select__option:active {
	background-color: transparent !important;
	padding: 5px 12px !important;
}
:local(.optionList) .react-select__option:hover {
	background-color: #DEEBFF !important;
	padding: 5px 12px !important;
}
:local(.optionList) .react-select__option {
	padding: 5px 12px !important;
	background-color: transparent !important;
}
.content-right {
	margin-top: 5px !important;
}