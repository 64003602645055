:local(.container){
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0 !important;
}
:local(.wrapper){
    display: flex;
}
:local(.search){
    flex: 1;
}
:local(.btnEdit){
    background-image: url('../../../../img/edit-big.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    border: none;
    margin-right: 15px;
    background-color: transparent;
}
:local(.btnEdit):focus{
    outline: 0;
}
:local(.btnEdit):hover,
:local(.btnEdit).active{
    background-image: url('../../../../img/icon-edit-big.svg');
}
:local(.table){
    flex: 1;
    display: flex;
    /* overflow-y: auto; */
}
:local(.row){
    display: flex;
    align-items: center;
}
:local(.cell){
    display: flex;
    align-items: center;
    min-height: 58px;
}
:local(.cell):after{
    content:'';
    min-height:inherit;
    font-size:0;
}
:local(.cell) span{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}
:local(.nameCell) span{
    max-width: 260px;
}
:local(.categoryCell) span{
    max-width: 136px;
}

/* Editing */
:local(.editBackdrop){
    background-color: #f4f4f4;
    padding:20px 40px 20px 55px;
    display: flex;
    border-top: 1px solid #e5e5e5;
}
:local(.editName){
    flex: 1;
    padding: 10px 0;
}