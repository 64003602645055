/*
	Conversations
 */

@import "variables";
$attachment-bar-height: 45px;

.conversations-panel {
  width: auto;
  position: relative;
  margin-left: 0;
  margin-right: 0;

  @media (min-width: #{$large-screen-size}) {
    margin-left: auto;
    margin-right: auto;
    width: 1640px;
  }

  @media (min-width: #{$optimized-max-screen-size}) and (max-width: #{$large-screen-size - 1}) {
    margin-left: 80px;
    margin-right: 80px;
  }

  @media (min-width: #{$optimized-screen-size}) and (max-width: #{$large-screen-size - 1}) {
    margin-left: 80px;
    margin-right: 80px;
  }

  @media (min-width: #{$optimized-min-screen-size}) and (max-width: #{$optimized-screen-size - 1}) {
    margin-left: auto;
    margin-right: auto;
    width: 1280px;
  }

  @media (min-width: #{$min-screen-size}) and (max-width: #{$optimized-min-screen-size - 1}) {
    margin-left: 24px;
    margin-right: 24px;
  }

  @media (max-width: #{$min-screen-size - 1}) {
    margin-left: 24px;
    margin-right: 24px;
  }

  .pane-detail {
    .user-info-toggle {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 12px;
      height: 100%;
    }
  }

  .pane.pane-detail {
    .chat-wrapper {
      display: flex;
      flex-direction: row;
    }
    .chat-content-wrapper {
      width: 0;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}

.queue-tab-manager {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1), 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: white !important;

  .Mui-selected {
    background-color: #f9f9f9;

    span {
      color: #0a3ab4;
    }
  }

  button {
    min-width: 12.6rem !important;

    span {
      color: #716f6f;
      text-transform: capitalize;
    }
  }
}

.isClickDisabled {
  opacity: 0.5;
}

.active-tab-manager-notifications {
  span {
    color: #db4437;
  }
}

.chat-messages {
  /* Is there a reason this is set to scroll? */
  /* overflow-y: scroll; */
  overflow-y: auto;
  overflow-x: hidden;
  flex: 2;
}
// http://codepen.io/samuelkraft/pen/Farhl
.chat-message {
  margin: 20px;
  max-width: 400px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  word-break: break-word;
  font-size: 16px;
  img {
    max-width: 100%;
  }
  &.sent {
    float: right;
    background: #2e7de1;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      right: -7px;
      height: 20px;
      border-left: 20px solid #2e7de1;
      border-bottom-left-radius: 16px 14px;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: -26px;
      width: 26px;
      height: 21px;
      background: white;
      border-bottom-left-radius: 10px;
    }
    .body {
      color: #fff;
      overflow: hidden;
      a {
        color: #fff;
      }
      .file-caption {
        text-align: left;
      }
    }
  }
  &.received {
    float: left;
    background: #ebebeb;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: -7px;
      height: 20px;
      border-right: 20px solid #ebebeb;
      border-bottom-right-radius: 16px 14px;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -26px;
      width: 26px;
      height: 21px;
      background: white;
      border-bottom-right-radius: 10px;
    }
    .body {
      color: #000;
      .file-caption {
        text-align: left;
      }
    }
  }
  .body {
    line-height: 22px;
    white-space: pre-line;
    a + .file-caption {
      margin-top: 5px;
    }
    .file-caption {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.chat-message-feature {
  margin: 20px;
  max-width: 400px;
  padding: 10px 20px;
  position: relative;
  word-break: break-word;
  font-size: 16px;
  img {
    max-width: 100%;
  }
  &.sent {
    float: right;
    background: #228094;
    border-radius: 6px 6px 0 6px;
    &:before {
      content: "";
      position: absolute;
      bottom: -20px;
      right: 0px;
      height: 20px;
      border-left: 20px solid #228094;
      border-bottom-left-radius: 0px;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -21px;
      right: -1px;
      width: 26px;
      height: 21px;
      background: white;
      border-radius: 0 50% 0 0;
    }
    .body {
      color: #fff;
      overflow: hidden;
      a {
        color: #fff;
      }
      .file-caption {
        text-align: left;
      }
    }
  }
  &.received {
    float: left;
    background: #e3edef;
    border-radius: 6px 6px 6px 0;

    &:before {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0px;
      height: 20px;
      border-right: 20px solid #e3edef;
      border-bottom-right-radius: 0px;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -21px;
      left: 0px;
      width: 21px;
      height: 21px;
      background: white;
      border-radius: 50% 0 0 0;
    }
    .body {
      color: #000;
      .file-caption {
        text-align: left;
      }
    }
  }

  &.bot {
    float: right;
    background: #883ac7;
    border-radius: 6px 6px 0 6px;
    &:before {
      content: "";
      position: absolute;
      bottom: -20px;
      right: 0px;
      height: 20px;
      border-left: 20px solid #883ac7;
      border-bottom-left-radius: 0px;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -21px;
      right: -1px;
      width: 26px;
      height: 21px;
      background: white;
      border-radius: 0 50% 0 0;
    }
    .body {
      color: #fff;
      overflow: hidden;
      a {
        color: #fff;
      }
      .file-caption {
        text-align: left;
      }
    }
  }
  .body {
    line-height: 22px;
    white-space: pre-line;
    a + .file-caption {
      margin-top: 5px;
    }
    .file-caption {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.chat-input {
  display: flex;
  flex-direction: row;
  background-color: white;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.11);
  min-height: 45px;

  .attachment-bar {
    height: $attachment-bar-height;
    position: absolute;
    top: -$attachment-bar-height;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #cfcfcf;
    border-top: 1px solid #cfcfcf;
    box-sizing: border-box;
    display: none;
    z-index: 3;

    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
    background-color: #f8f8f8;
    //box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.3);

    &.visible {
      display: block;
    }

    .progress {
      position: absolute;
      height: 2px;
      border: 1px solid #2e7de1;
    }

    .attacmhent-preview-small {
      float: left;
      display: inline-block;
      height: 100%;
      width: 100%;

      img {
        max-height: 20px;
        max-width: 30px;
        width: auto;
        height: auto;
        margin: 13px 10px 15px 15px;
        display: block;
        line-height: 45px;
        position: absolute;
        left: 0;
        right: 0;
      }

      span {
        font-family: Roboto-Regular, Arial;
        font-size: 16px;
        letter-spacing: -0.4px;
        color: #000000;
        line-height: 45px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        width: 100%;
        padding: 0 45px 0 55px;
      }
    }

    .close-button {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin: 13px 15px 12px 15px;
      background-image: url("../src/img/close.png");
      position: absolute;
      right: 0;
    }
  }

  .form-control {
    flex: 1;
  }

  .status {
    position: absolute;
    left: 10px;
    top: -31px;
    opacity: 0.5;
  }
  .error {
    position: absolute;
    left: 10px;
    top: -31px;
  }

  .form-disabled {
    background: #f2f2f2;
    textarea {
      background: #f2f2f2;
    }
  }
}

.clearfix {
  clear: both;
}

.endchat-popover {
  position: absolute;
  right: 5px !important;
  z-index: 100;
  width: 244px;
  margin: -14px 7px;
  max-height: 130px;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  .arrow {
    position: absolute;
    top: -6px;
    right: 46px;
    width: 16px;
    height: 16px;
    background: #fff;
    transform: rotate(45deg);
  }

  .endchat-footer {
    display: flex;
    justify-content: center;
    margin: 10px;
    color: #000;

    :first-child {
      color: #797979;
      border-color: #797979;
    }

    :last-child {
      color: #fff;
    }
  }

  .endchat-body {
    margin: 10px;
  }
  .endchat-consumer-name {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.summary-popover {
  background-color: #f4f4f4;
}

.summary-transfer-details-container {
  max-height: 465px;
  padding: 10px;
  overflow-y: scroll;
  background-color: #f4f4f4;
}

.summary-modal-mask {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 35px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);

  & > span {
    align-self: flex-end;
    font-size: 17px;
    color: #272727;
  }

  .close-summary-icon {
    align-self: flex-end;
    margin-right: 10px;
    width: 15px;
    height: 15px;
    object-fit: contain;
    cursor: pointer;
  }
}

.transfer-details {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #ffffff;
  border: solid 0.5px #bdbdbd;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .transfer-info {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 10px;

    & > p {
      width: 240px;
      margin: 0;
      font-size: 15px;
      color: #000000;
    }

    & > span {
      margin-left: 10px;
      width: 65px;
      font-size: 13px;
      font-weight: 500;
      color: #797979;
    }
  }

  .transfer-comment {
    padding: 10px;
    box-shadow: inset 0 0.5px 0 0 #bdbdbd;
    font-size: 15px;
    text-align: left;
    color: #797979;

    & > p {
      margin: 0;
    }
  }
}

.end-chat-modal {
  @extend .force-app-modal-styles;
}

.post-chat-modal {
  @extend .force-app-modal-styles;
  width: 424px !important;

  .disposition-codes {
    margin-bottom: 12px;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px 15px 15px;
    .btn + .btn {
      margin-left: 10px;
    }
  }

  .modal-body {
    padding-bottom: 10px;
    section {
      &:first-child {
        margin: 2px 0 16px;
      }
    }
    textarea {
      width: 100%;
      height: 120px;
      padding: 10px;
      border: 1px solid rgba(121, 121, 121, 0.9);
      border-radius: 2px;
      &.is-required {
        border-color: $brand-primary;
        &::-webkit-input-placeholder {
          color: $brand-primary;
        }
        &:-moz-placeholder {
          color: $brand-primary;
        }
        &::-moz-placeholder {
          color: $brand-primary;
        }
        &:-ms-input-placeholder {
          color: $brand-primary;
        }
      }
    }
    label {
      width: 100%;
    }
    .Select {
      width: 100%;
    }
  }

  .tag-list {
    max-height: 160px;
    overflow-y: auto;
  }

  .tag-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding-left: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      margin-bottom: 10px;
    }
  }

  .ui-dropdown {
    margin-top: 4px;
    .Select-control {
      border-color: rgba(121, 121, 121, 0.9);
      &:hover {
        box-shadow: none;
      }
    }
    .Select-placeholder {
      color: rgba(121, 121, 121, 0.9);
    }
    .Select-arrow {
      border-color: #bdbdbd transparent transparent;
    }
  }

  .ui-dropdown.is-required {
    .Select-control {
      color: $brand-primary;
      border-color: $brand-primary;
    }
    .Select-placeholder {
      color: $brand-primary;
    }
    .Select-arrow {
      border-color: $brand-primary transparent transparent;
    }
  }
}

.post-chat-modal {
  .modal-body .label-text {
    margin-bottom: 8px;
  }
  textarea:focus {
    border: 1px solid $brand-primary;
  }
}

.data-ellipsis-list {
  margin: 10px 0 0 0;
  padding: 0;
  li {
    list-style-type: none;
    margin: 0 0 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: nowrap;
    word-break: break-all;
    > span:first-child {
      color: #999999;
      white-space: nowrap;
    }
    > span:nth-child(2) {
      position: relative;
      flex: 1;
      margin: 0 4px;
      border-bottom: 1px dotted #b9b9b9;
    }
  }
}

.events-ellipsis-list {
  margin: 10px 0 0 0;
  padding: 0;
  li {
    font-size: 13px;
    list-style-type: none;
    margin: 0 0 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: nowrap;
    word-break: break-word;
    .dotted-line {
      position: relative;
      flex: 1;
      margin: 0 4px;
      border-bottom: 1px dotted #b9b9b9;
    }
  }
}

.disconnect-modal {
  @extend .force-app-modal-styles;
  .modal-content {
    width: 400px !important;
  }
  .modal-footer {
    .btn + .btn {
      margin-left: 10px;
    }
  }
  textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    border: 1px solid rgba(121, 121, 121, 0.9);
    border-radius: 2px;
  }
  ul.data-ellipsis-list li span {
    color: $text-color;
  }
  .modal-body section:first-child {
    margin: 7px 0 20px 0;
  }
}
.new-conversation-modal {
  .modal-header {
    max-height: 55px !important;
  }
  .modal-footer {
    .img-wrapper {
      display: inline-block;
      width: 26px;
      text-align: left;
    }
  }
  .Select-value-label {
    color: #000;
  }
  .Select-clear {
    display: none;
  }
  .Select-option {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    border-top: 1px solid #e5e5e5;
    &:first-child {
      border-top: none;
    }
  }
  .Select-menu-outer {
    box-shadow: 0 2px 12px #ccc;
  }
}

.double-arrow.is-open {
  transform: scaleX(-1);
}

.conversations-list {
  display: flex;
  flex-direction: column;
  .list > div > div:last-child {
    border-bottom: none !important;
  }
  > .list {
    overflow-x: hidden;
    overflow-y: initial;
  }
  .paged-list-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .paged-list {
    flex: 1;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    // margin-top: 10px;

    .conversation-list-item {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      }
    }
    .caption {
      width: 226px !important;
    }
    &.has-zero {
      .loader-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .loader-contents {
          display: block;
          .spinner-caption {
            margin-top: 24px;
          }
        }
        .spinner {
          left: 50%;
          margin-top: -12px;
          margin-left: -12px;
          position: absolute;
        }
      }
    }
    .cancel-search-button {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 12px;
      button {
        background: none;
        border: none;
        color: #0a3ab4;
        :focus {
          outline: none;
        }
      }
    }
  }
}

/* .campaign-list-item { */
/*   transition: opacity 400ms ease-out; */
/*   opacity: 0; */
/*   &.fade-in { */
/*     /\* outline: 1px solid red; *\/ */
/*     opacity: 1; */
/*     /\* animation: fade-in 400ms ease-out; *\/ */
/*   } */
/* } */

.conversation-history-message {
  &:first-child {
    .timestamp {
      margin-top: 8px;
    }
  }
}

.timestamp {
  font-size: 14px;
}

.start-new-chat {
  font-size: 17px;
  white-space: nowrap;
  color: $brand-primary;
  &.faded-text {
    opacity: 0.4;
  }
}

.start-new-chat-btn {
  &.disabled .start-new-chat {
    opacity: 0.4;
  }
}

.stream-select-container {
  &.disabled label {
    color: #aaa !important;
  }
}

.bot-message-options {
  display: block;
}

.bot-message-option {
  display: inline-block;
}

.user-option-in-select {
  width: 100%;
  height: 100%;
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  .option-label {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.active-user-tooltip {
  width: 100%;
  height: 100%;
  > div {
    width: 100%;
    height: 100%;
  }
}

.your-queue-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  background: #f7f7f7;
  font-size: 13px;
  color: rgba(121, 121, 121, 0.9);
}

.agent-status-card {
  display: flex;
  align-items: center;
  padding: 16px 15px 16px 24px;
  font-size: 13px;
  color: rgba(121, 121, 121, 0.9);
  background: #fff;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  h3 {
    align-items: center;
    display: flex;
    margin: 0 0 0 4px;
    font-weight: 600;
    font-size: 16px;
    color: #29302e;
  }
  p {
    margin: 3px 0 1px 22px;
    color: rgba(121, 121, 121, 0.9);
  }
  a {
    margin-left: 22px;
    color: #2e7de1;
  }
}
.agent-away-notification,
.agent-offline-notification {
  h3:before {
    display: inline-block;
    content: "";
    width: 12px;
    height: 12px;
    margin-right: 6px;
    vertical-align: middle;
  }
}
.agent-away-notification h3:before {
  background: url(../src/img/icon-away.svg) no-repeat;
}
.agent-offline-notification h3:before {
  background: url(../src/img/icon-offline.svg) no-repeat;
}

.agent-offline-notification {
  h3:before {
    display: inline-block;
    content: "";
    width: 12px;
    height: 12px;
    background: url(../src/img/icon-offline.svg) no-repeat;
    margin-right: 6px;
    vertical-align: middle;
  }
}

.agent-busy-notification {
  h3:before {
    display: inline-block;
    content: "";
    background: url(../src/img/icon-busy.svg) no-repeat;
    width: 12px;
    height: 12px;
    margin-right: 6px;
    vertical-align: middle;
  }
}

.conversationSearchBox {
  padding: 8px 24px 16px !important;
  .MuiOutlinedInput-root {
    background-color: #ffffff !important;
    &:hover fieldset {
      border-color: #3f51b5 !important;
    }
  }
  .resetIcon {
    font-size: 16px;
    cursor: pointer;
  }
  .searchIcon {
    font-size: 16px;
  }
  input {
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 1.5;
    border: none;
    height: 20px;
    color: #29302e;
    padding-left: 16px;
    &::placeholder {
      color: #7a6769;
    }
  }
}
.search-bottom-container {
  display: inline-flex;
  width: 100%;
  .refresh-icon {
    display: inline-block;
    padding-top: 2px;
  }
  .filter-text {
    margin-left: -5px;
  }
  .refresh-svg {
    top: 6px;
  }
  .filter-button-container {
    padding: 2px 2px 2px 25px !important;
    margin: 2px 20px !important;
    width: 100%;
    .filter-button {
      font-size: 12px;
      line-height: 16px;
      padding: 4px 6px !important;
      float: right;
      margin-right: 0px;
    }
  }
}
.hide-search-bottom-container {
  display: none;
}
