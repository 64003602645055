/*
  Autoresponders detail pane
  Route: /admin/autoresponders
*/

.autoresponders {
  section.has-error {
    .label-text {
      color: $brand-danger;
    }
    textarea {
      border-color: $brand-danger;
    }
  }
}