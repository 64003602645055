:local(.wrapper){
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}
:local(.anonSession){
    flex: 2;
    position: relative;
}
:local(.anonInner) {
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 11px;
    color: #797979;
}

:local(.btnCannedResponse){
    width: 24px;
    height: 24px;
    background-image: url(../../../../img/icon-canned-response-toggle.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    margin-left: 10px;
    background-color: transparent;
}
:local(.btnCannedResponse):hover{
    background-image: url(../../../../img/icon-canned-response-toggle-active.svg);
}
:local(.btnCannedResponse):focus{
    outline: none;
}
:local(.btnAttach){
    width: 24px;
    height: 24px;
    background-image: url(../../../../img/paperclip.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    border-radius:50%;
}
:local(.btnAttach):focus{
    outline: none;
    background-color:#99bdf7;
}
:local(.btnAttach):hover{
    background-image: url(../../../../img/paperclip.svg);
    background-color: #cbdffb;
}