:local(.Banner) {
  position: relative;
  height: 130px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

:local(.Image) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

:local(.CloseBox) {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border-radius: 0 10px 0 10px;
  background: #797979;
}