:local(.header){
	padding: 20px;
	background-color: #ffffff;
	position: relative;
}
:local(.header):after{
	content: "";
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 10px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
	z-index: 2;
}

:local(.wrapper){
	display: flex !important;
	flex-flow: column;
	/* margin-left: 4rem; */
}
:local(.stats){
	flex: 1;
	overflow-y: auto;
	position: relative;
}
