:local(.WidgetList) {
  display: flex;
  flex-direction: column;
  min-height: 0; /* FF fix */
}

:local(.Content) {
  overflow-y: auto;
}

:local(.CreateWidget) {
  height: 30px;
}
