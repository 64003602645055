:local(.searchWrapper){
  flex: 1;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
:local(.wrapper){
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), inset 0 -0.5px 0 0 #bdbdbd;
  background: #fff;
}

:local(.searchInput){
    flex: 1 auto;
    position: relative;
}

:local(.searchInput) input{
  width: 100%;
  border-radius: 100px;
  background: transparent;
  border: solid 1px #bdbdbd;
  font-size: 15px;
  position: relative;
  z-index: 1;
}
:local(.searchInput) input.active{
  background-color: #ffffff;
  border: solid 1px #2e7de1;
}

:local(.clearSearch){
  position: absolute;
  right: 0;
  top: 50%;
  background: none;
  border:none;
  margin-top: -15px;
  z-index: 1;
}
:local(.clearSearch):focus{
  outline: none;
}

:local(.placeholder){
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f4f4f4;
  border-radius: 100px;
  text-align: center;
  line-height: 34px;
  color: #797979;
}
:local(.placeholder) img{
  vertical-align: inherit;
  margin-right: 5px;
}
