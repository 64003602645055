.notification.notification-info .notification-title {
  /*
    This needs to be !important because react-notification-system
    defines inline styles on its components and provides no way to modify
    .notification-title styles.
  */
  color: #458be4 !important;
}
.notification .notification-dismiss{
	font-size: 0 !important; // hides x
	background-color: transparent !important;
	width:18px !important;
	height: 18px !important;
	background-repeat: no-repeat;
	right: 19px !important; 
	border-radius: 0 !important;
}
.notification.notification-info .notification-dismiss{
	background-image: url(../src/img/icon-alert-info-close.svg)
}
.notification.notification-error .notification-dismiss{
	background-image: url(../src/img/icon-alert-error-close.svg)
}
.notification.notification-success .notification-dismiss{
	background-image: url(../src/img/icon-alert-success-close.svg);
	background-position: -18px;
}