.chevron {
  &:before {
    content: "";
    display: inline-block;
    width: 9px;
    height: 9px;
    border: 1px solid $gray-light;
    border-top: 0;
    border-right: 0;
    transform: rotate(-45deg);
  }
}

.chevron-right:before {
  transform: rotate(-135deg);
}

.pointer-arrow {
  position: relative;
  top: 12px;
  margin-left: 2px;
  margin-right: 2px;
  width: 0;
  height: 0;
  border-color: $brand-primary transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
}

.pointer-arrow-up {
  top: -11px;
  border-color: transparent transparent $brand-primary transparent;
  border-width: 5px;
}

.mouse-mask {
  pointer-events: none;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loading-mask {
  z-index: 8000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: #fff; */
    background: #000;
    opacity: 0;
    transition: all 100ms linear;
  }
  &.mounted .mask {


    /* opacity: 0.1; */

    /* background: #000; */
  }
  .spinner {
    &:before, &:after {
      display: none;
    }
  }
}

.diamond {
  z-index: -1;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  /* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 4px 0 rgba(169, 169, 169, 0.5);
  background: #fff;
  transform: rotate(45deg);
}