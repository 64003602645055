:local(.ButtonStyle) {
  position: relative;
  min-height: 18px;
  margin: 5px;
  padding: 16px 20px;
  border-radius: 5px;
  border-width: 0;
  background: rgba(222, 232, 245, 0.5);
  transform: translateY(0);
  transition: background 240ms ease-out;
  cursor: default;

  font-family: Open Sans, Segoe UI, Trebuchet MS, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #007bff;
}

:local(.ButtonStyle:active, .ButtonStyle:focus) {
  outline: none;
}
