
:local(.container){
    border: 1px solid rgba(121, 121, 121, 0.9);
    border-radius: 3px;
    padding-top: 2px;
    background: #fff;
}
:local(.container) .form-control:focus{
    box-shadow:none;
}
.label-input.has-error :local(.container){
    border-color: #db4437;
}
:local(.footer){
    position: relative;
    padding: 2px 10px 5px;
}
:local(.innerFooter){
    padding-right: 55px;
}
:local(.separator){
    height: 2px;
    border: solid 1px #bdbdbd;
    margin: 2px 0 10px;
}
:local(.label){
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    color: rgba(121, 121, 121, 0.9);
    margin-right: 10px;
}
:local(.label).block{
    display:block;
    margin-bottom: 5px;
}
:local(.tag){
    font-size: 11px;
    border-radius: 11.5px;
    background-color: #2e7de1;
    color: #ffffff;
    padding: 5px 8px;
    text-transform: uppercase;
    margin-right: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    display: inline-block;
    font-weight: normal;
    max-width: 435px;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
}
:local(.tag:focus),
:local(.tag:hover){
    color:#fff;
    text-decoration: none;
}

:local(.limit){
    position: absolute;
    right: 10px;
    top: 15px;
    font-size: 11px;
  text-align: right;
  color: rgba(121, 121, 121, 0.9);
}