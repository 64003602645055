:local(.Banner) {
  display: flex;
  background: #eff5fd;
  height: 150px;
  min-height: 150px;
  padding: 20px 20px 0 30px;
}

:local(.Pane) {

}

:local(.LeftPane) {
  width: 60%;
}

:local(.RightPane) {
  align-self: flex-end;
  width: 40%;
}

:local(.Heading) {
  margin-top: 5px;
  margin-bottom: 5px;
  /* text-transform: uppercase; */
  font-size: 20px;
  font-weight: bold;
  color: #2e7de1;
}

:local(.Info) {

}

:local(.Img) {
  width: 100%;
}
