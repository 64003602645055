:local(.wrapper){
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}
:local(.head){
    height: 55px;
    background-color: #f4f4f4;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    /* padding-left: 10px; */
    /* padding-right: 30px; */
    z-index: 1;
}
:local(.column){
    font-size: 13px;
    font-weight: 500;
    color: #797979;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
}
:local(.body){
    flex: 1;
    overflow-y: auto;
    max-height: 400px;
}
:local(.sortIcon){
    width: 8px;
    height: 12px;
    position: relative;
    margin-left: 12px;
    vertical-align: middle;
}
:local(.sortIcon) svg{
    position: absolute;
    left: 0;
    color: #797979;
}
:local(.sortIcon) [data-icon="caret-up"]{
    top: -5px;
}
:local(.sortIcon) [data-icon="caret-down"]{
    bottom: -2px;
}
:local(.sortIcon).desc [data-icon="caret-up"]{
    color: rgba(121, 121, 121, 0.4);
}
:local(.sortIcon).desc [data-icon="caret-down"]{
    color: #2e7de1;
}
:local(.sortIcon).asc [data-icon="caret-up"]{
    color: #2e7de1;    
}
:local(.sortIcon).asc [data-icon="caret-down"]{
    color: rgba(121, 121, 121, 0.4);
}

:local(.row){
    min-height: 58px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
}