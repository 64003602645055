.admin {

  section:first-child {
    padding-top: 0;
  }

  .panes .pane.pane-list .sub-nav .list {
    top: 46px !important;
  }
  .panes .pane.pane-detail .pane-content{
    padding: 0;
  }

  .sub-nav {
    flex: 1;
  }

  .sub-nav-item {
    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
  }

  .detail-footer {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    text-align: right;
    padding: 10px 8px;
    background: #fff;
    height: 56px;
  }

  .content-body {
    width: $pane-detail-width;
    display: flex;
    flex-direction: column;
    flex: 1;
    .pm-feature-section > div > section,
    > section {
      padding: 30px;
      margin: 0;
      // margin: 40px 0 28px;
      box-shadow: inset 0 -0.5px 0 0 #bdbdbd;
      &:last-child{
        box-shadow: none;
      }
      .checkbox-grid {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
        width: 60%;

      }
      hr{
        display: none;
      }
      h5 {
        font-weight: bold !important;
      }
    }
  }

  section {
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }

  .edit-code-input {
    display: flex;
    justify-content: space-between;
  }

}

// .panes .chat-logs-detail .content-body.pane-content {
//   padding-left: 0;
//   padding-right: 0;
// }

.chat-logs-detail {

  // .content-body > section {
  //   margin: 0;
  //   padding: 30px;
  //   border-bottom: 1px solid #dcdcdc;
  //   &:first-child {
  //     padding-top: 0;
  //   }
  //   &:last-child {
  //     border-bottom: none;
  //   }
  // }

  .sub-header + section {
    margin-top: 0;
  }

  // hr {
  //   display: none;
  // }

}

.chat-logs-detail-spinner {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin-top: -20px !important;
  margin-left: -20px !important;
}

// .panes .pane.pane-detail .chat-logs-detail .pane-content h5 {
//   font-weight: bold;
// }

/* .edit-tags-modal { */

/*   bottom: auto !important; */

/*   .app-tooltip { */
/*     margin-left: 4px; */
/*   } */

/*   .sub-header { */
/*     -webkit-font-smoothing: subpixel-antialiased; */
/*     hr { */
/*       display: none; */
/*     } */
/*   } */

/*   p { */
/*     font-size: 13px; */
/*   } */

/*   .add-tag-btn { */
/*     font-size: 17px; */
/*     border-color: $brand-primary; */
/*     color: $brand-primary; */
/*     background: #fff; */
/*   } */

/*   .input-with-addon { */
/*     display: inline-block; */
/*     position: relative; */
/*     .label-input { */
/*       display: inline-block; */
/*     } */
/*     .label-text { */
/*       color: rgba(121, 121, 121, 0.9); */
/*     } */
/*     .input-with-addon-wrapper { */
/*       display: flex; */
/*       width: 315px; */
/*       height: 36px; */
/*       padding-right: 8px; */
/*       border: solid 1px rgba(121, 121, 121, 0.9); */
/*       border-radius: 3px; */
/*     } */
/*     input { */
/*       width: 100%; */
/*       height: 100%; */
/*       padding-left: 8px; */
/*       padding-right: 8px; */
/*       border: none; */
/*       color: #000000; */
/*     } */
/*     .addon-text { */
/*       display: flex; */
/*       justify-content: flex-end; */
/*       align-items: center; */
/*       width: 60px; */
/*       height: 100%; */
/*       font-size: 13px; */
/*       font-weight: normal; */
/*       color: rgba(121, 121, 121, 0.9); */
/*     } */
/*   } */

/*   .section-separator { */
/*     position: relative; */
/*     left: -30px; */
/*     width: calc(100% + 60px); */
/*     height: 1px; */
/*     margin: 24px 0; */
/*     border-bottom: 1px solid #bdbdbd; */
/*   } */

/*   .modal-body { */
/*     overflow-x: hidden; */
/*     overflow-y: auto !important; */
/*     section h5 { */
/*       margin-bottom: 12px; */
/*     } */
/*   } */

/*   .modal-body section { */
/*     &.header-section { */
/*       margin-bottom: 16px; */
/*       p { */
/*         font-size: 13px; */
/*       } */
/*       section { */
/*         margin-top: 12px; */
/*       } */
/*     } */
/*     &.options-section { */
/*       margin-top: 16px; */
/*       margin-bottom: 16px; */
/*     } */
/*     &.table-section { */
/*       margin-top: 16px; */
/*     } */
/*   } */

/* } */

.weak-text {
  color: #ccc;
}

.new-code-modal {
  .label-input + .label-input {
    /* margin-top: 16px; */
    margin-left: 12px;
  }
}

.shortcut-code-text {
  letter-spacing: 4px;
}

.checkbox-section {
  display: flex;
  alignItems: center;
  .label-checkbox + .label-checkbox {
    margin-left: 20px;
    margin-top: 0;
  }
}

.new-code-modal {
  @extend .force-app-modal-styles;
  .modal-body {
    @extend .stretch-form-content;
  }
  .label-input:first-child .label-text {
    display: flex;
    justify-content: space-between;
  }
  .app-tooltip {
    margin-left: 4px;
  }
  .modal-footer {
    .btn-primary {
      margin-left: 10px;
    }
  }
}

/* .tag-table { */
/*   margin-top: 16px; */
/*   input { */
/*     height: 34px !important; */
/*     padding-left: 8px; */
/*     padding-right: 8px; */
/*   } */
/*   a { */
/*     cursor: pointer; */
/*     &.disabled { */
/*       cursor: default; */
/*       /\* color:  *\/ */
/*     } */
/*   } */
/*   .separator:before { */
/*     content: "/"; */
/*     margin: 0 0.3em; */
/*     color: $gray-light; */
/*   } */
/*   div[class*='column'] { */
/*     /\* outline: 1px solid red; *\/ */
/*   } */
/*   .column-1 { */
/*     /\* flex: 1; *\/ */
/*     width: 200px; */
/*     margin-right: 6px; */
/*     padding-right: 24px; */
/*   } */
/*   .column-2 { */
/*     width: 100%; */
/*     /\* flex: 4; *\/ */
/*   } */
/*   /\* .column-3 { *\/ */
/*   /\*   flex: 1; *\/ */
/*   /\*   text-align: right; *\/ */
/*   /\* } *\/ */
/*   .table-header { */
/*     display: flex; */
/*     align-items: center; */
/*     @extend .heading-light-cap; */
/*   } */
/*   .table-body { */
/*     margin-bottom: 12px; */
/*   } */
/*   .table-row { */
/*     width: 100%; */
/*     border-bottom: 1px solid $gray-lighter; */
/*   } */
/*   .row-content { */
/*     display: flex; */
/*     justify-content: space-between; */
/*     align-items: center; */
/*     width: 100%; */
/*     height: 46px; */
/*   } */
/*   .new-row { */
/*     input { */
/*       //@extend .borderless; */
/*       border: solid 1px rgba(121, 121, 121, 0.9); */
/*       width: 100%; */
/*       height: 100%; */
/*       //padding-left: 0; */
/*       font-size: $font-size-base; */
/*     } */
/*   } */
/* } */

.confirm-delete-agent-modal {
  @extend .force-app-modal-styles;
  width: 400px !important;
}

.confirm-router-switch-modal {
  @extend .force-app-modal-styles;
  width: 410px !important;
}

.admin-nav-list {
  .sub-nav-item .list-item {
    align-items: center;
    &.active {
      color: $brand-primary !important;
    }
    .fade{
      opacity: .2;
    }
  }
}

/* .tag-feature-modal { */
/*   .modal-body { */
/*     padding: 20px !important; */
/*   } */
/* } */

.agents-detail {
  // section {
  //   margin: 10px 0;
  // }
  // h5 {
  //   font-weight: bold !important;
  // }
  // .content-body {
  //   padding: 0 !important;
  //   > section {
  //     margin: 0;
  //     padding: 20px 30px;
  //     /* border-bottom: 1px solid #cccccc; */
  //     border-bottom: 1px solid #d9d9d9;
  //     &:last-child {
  //       border-bottom: none;
  //     }
  //     &:first-child{
  //       padding-top: 30px;
  //     }
  //   }
  // }
  // hr {
  //   display: none;
  // }
  .initiate-chats-section {
    .label-checkbox {
      margin-top: 20px;
    }
  }
}

.agent-max-chats-input {
  width: 42px;
  padding: 0;
  text-align: 0;
}

.autoresponders {
  // h5 {
  //   font-weight: bold !important;
  // }
  // hr {
  //   display: none;
  // }
  .content-body {
    // padding-left: 0 !important;
    // padding-right: 0 !important;
    // > section {
    //   padding-left: 30px;
    //   padding-right: 30px;
    //   &:first-child {
    //     border-bottom: 1px solid #e0e0e0;
    //     padding-bottom: 10px;
    //     margin-bottom: 0;
    //   }
    //   &:last-child {
    //     margin-top: 24px;
    //   }
    // }
  }
  textarea {
    width: 354px;
    height: 96px;
    padding: 8px;
    border: solid 1px rgba(121, 121, 121, 0.9);
    color: #000;
  }
  .sub-header + section {
    margin-top: 0px;
  }
}

.chat-routing {
  // h5 {
  //   font-weight: bold !important;
  // }
  // hr {
  //   display: none;
  // }
  // .content-body {
  //   padding-left: 0 !important;
  //   padding-right: 0 !important;
  //   > section {
  //     padding-left: 30px;
  //     padding-right: 30px;
  //     &:first-child {
  //       border-bottom: 1px solid #e0e0e0;
  //       padding-bottom: 10px;
  //       margin-bottom: 0;
  //     }
  //     &:last-child {
  //       border-bottom: none;
  //     }
  //   }
  // }
  .alert-warning{
    padding: 5px;
    background: #fff8e5;
    color: #797979;
    border-radius: 0;
    border: none;
  }
  .software-list{
    width:245px;
  }
  .sub-header + section {
    margin-top: 0px;
  }
}
