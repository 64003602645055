:local(.HeaderStyled) {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 70px;
  font-family: sans-serif;
  transition: box-shadow 500ms linear;
}

:local(.FixedHeader) {
  position: absolute;
  width: 100%;
  min-height: 70px;
  display: flex;
  z-index: 1;
}

:local(.HeaderElements) {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}

:local(.TitleBar) {
  display: flex;
  align-items: center;
  overflow: hidden;
}

:local(.Title) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 15px;
}

:local(.BarLogo) {
  border-radius: 50%;
  border-color: white;
  border-width: 1px;
  border-style: solid;
  width: 30px;
  height: 30px;
  margin: 0 20px;
}

:local(.Controllers) {
  display: flex;
  align-items: center;

  > div {
    height: 22px;
    width: 22px;
    cursor: pointer;
    margin-right: 20px;
  }
}
