:local(.CreateApi) {
  margin: 16px 30px;
}

:local(.Button) {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: none;
}

:local(.Text) {
  margin-top: 2px;
  margin-left: 12px;
  color: #2e7de1;
}

:local(.shouldMask) {
  opacity: 0.3;
}
