:local(.bannerWrapper){
	background: #fff;
    opacity: 0.95;
    padding: 40px 20px;
}

:local(.disabled){
	pointer-events: none;
	opacity: .2;
}
:local(.disabled) *{
	pointer-events: none;
}
:local(.wrapper) {
	display: flex;
	flex-direction: column;
}