.rsbc-switch-button {
    position: relative;
    line-height: 100%;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.rsbc-switch-button label {
    cursor: pointer
}

.rsbc-switch-button input[type="checkbox"] {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden
}

.rsbc-switch-button>label:first-child {
    display: inline-block;
    min-width: 140px;
    text-align: right;
    margin: 0 6px 0 12px
}

.rsbc-switch-button label+label {
    margin-left: 6px
}

.rsbc-switch-button input[type="checkbox"]+label {
    display: inline-block;
    position: relative;
    margin: 0;
    outline: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.rsbc-switch-button.rsbc-switch-button-flat-round input[type="checkbox"]+label {
    padding: 2px;
    width: 48px;
    height: 24px;
    vertical-align: middle;
    background-color: #eee;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    -webkit-transition: background .4s;
    -moz-transition: background .4s;
    -o-transition: background .4s;
    transition: background .4s
}

.rsbc-switch-button.rsbc-switch-button-flat-round:hover input[type="checkbox"]+label {
    background-color: #6f6f6f
}

.rsbc-switch-button.rsbc-switch-button-flat-round input[type="checkbox"]+label:before,
.rsbc-switch-button.rsbc-switch-button-flat-round input[type="checkbox"]+label:after {
    display: inline-block;
    position: absolute;
    content: ""
}

.rsbc-switch-button.rsbc-switch-button-flat-round input[type="checkbox"]+label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #fff;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    -webkit-transition: background .4s;
    -moz-transition: background .4s;
    -o-transition: background .4s;
    transition: background .4s
}

.rsbc-switch-button.rsbc-switch-button-flat-round input[type="checkbox"]+label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 16px;
    background-color: #d5d5d5;
    -webkit-border-radius: 52px;
    -moz-border-radius: 52px;
    border-radius: 52px;
    -webkit-transition: margin .4s, background .4s;
    -moz-transition: margin .4s, background .4s;
    -o-transition: margin .4s, background .4s;
    transition: margin .4s, background .4s
}

.rsbc-switch-button.rsbc-switch-button-flat-round input[type="checkbox"]:checked+label {
    background-color: #579fcb
}

.rsbc-switch-button.rsbc-switch-button-flat-round input[type="checkbox"]:checked+label:after {
    margin-left: 24px;
    background-color: #3887b7
}