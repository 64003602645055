.empty-pype-description {
  width: 360px;
  height: 100px;
  background-color: #f7f7f7;
  padding: 16px;

  &::before {
    display: inline;
    content: 'Add a description for your Pype';
    background: url(../src/img/icon-pencil-blue.svg) no-repeat left center;
    height: 18px;
    padding-left: 26px;
    color: #2e7de1;
  }
}
