:local(.ChatWindow) {
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 660px;
  overflow-y: hidden;
  margin: 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  transition: opacity 240ms ease-out, transform 240ms ease-out;
}

:local(.isNotVisible) {
  opacity: 0;
  transform: translateY(12px);
}

:local(.isVisible) {
  opacity: 1;
  transform: translateY(0);
}

:local(.nudgeTop) {
  margin-top: 64px;
}
