:local(.MessageStyle) {
  position: relative;
  display: flex;
  padding-right: 20px;
  padding-top: 20px;
}

:local(.Content) {
  position: relative;
  bottom: 5px;
  display: flex;
  align-items: center;
  max-width: 95%;
  min-height: 50px;
  padding: 13px 20px;
  background-color: #e5e5ea;
  border-radius: 10px;
  white-space: pre-line;
  font-family: 'Open Sans', 'Segoe UI', Trebuchet MS, sans-serif;
  font-size: 16px;
  word-break: break-word;
}

:local(.ProfileIconStyle) {
  z-index: 4;
  position: absolute;
  left: 20px;
  width: 35px;
  height: 35px;
  bottom: 0;
  border: 1px solid #bdbdbd;
  display: inline-block;
  border-radius: 100px;
}

:local(.Bubble) {
  position: absolute;
  bottom: -7px;
  height: 16px;
}

:local(.BeforeLeftBubble) {
  z-index: 2;
  left: -4px;
  border-right: 20px solid;
  border-bottom-right-radius: 16px 14px;
  transform: translate(-1px, -2px);
}

:local(.AfterLeftBubble) {
  z-index: 3;
  left: 4px;
  width: 26px;
  background: #fff;
  border-bottom-right-radius: 10px;
  transform: translate(-30px, -2px);
}

:local(.BeforeRightBubble) {
  right: -8px;
  border-left: 20px solid;
  border-bottom-left-radius: 16px 14px;
  transform: translate(0, -2px);
}

:local(.AfterRightBubble) {
  z-index: 1;
  right: -56px;
  width: 26px;
  background: #fff;
  border-bottom-left-radius: 10px;
  transform: translate(-30px, -2px);
}
