:local(.DesignModal) {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  overflow: visible !important;
  width: 924px !important;
  border-radius: 10px !important;
  outline: none !important;
}

:local(.ModalContent) {
  max-width: none !important;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

:local(.ModalHeader) {
  font-size: 17px;
  border-radius: 10px 10px 0 0 !important;
}

:local(.ModalBody) {
  display: flex;
  flex-direction: row;
  padding: 0;
  box-shadow: 0 1px 0 0 #bdbdbd;
  overflow-y: auto;
}

:local(.ModalFooter) {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  padding-bottom: 11px;
  padding-right: 20px;
  border-radius: 0 0 10px 10px !important;
  text-align: right;
}

:local(.Heading) {
  font-weight: bold;
}

:local(.BackButton) {
  width: 78px;
  height: 38px;
  padding: 0;
  font-size: 17px;
  border-radius: 5px;
  border: 1px solid #797979;
  margin-right: 10px;
  color: #797979;
  background: #fff;
}

:local(.SaveButton) {
  height: 38px;
  padding: 0 18px;
  font-size: 17px;
  border-radius: 5px;
}

:local(.Config) {
  width: 50%;
  flex: 1;
}

:local(.Preview) {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  width: 50%;
  padding: 20px;
  background: #fff;
}

:local(.ChatWindow) {
}

:local(.Launcher) {
  cursor: pointer;
  user-select: none;
}

:local(.ChatCircleInPreview) {
  margin-top: 20px;
}

:local(.ChatAnchorInPreview) {
  position: absolute;
}

:local(.ChatCircleInPreview.top-left) {
  position: absolute;
  top: 8px;
  left: 40px;
  margin: 0;
}

:local(.ChatCircleInPreview.top-right) {
  position: absolute;
  top: 8px;
  right: 20px;
  margin: 0;
}

:local(.ChatCircleInPreview.bottom-left) {
  align-self: flex-start;
  margin-left: 24px;
}

:local(.ChatAnchorInPreview.top-left) {
  top: 0;
  left: 20px;
}

:local(.ChatAnchorInPreview.top-right) {
  top: 0;
  right: 20px;
}

:local(.ChatAnchorInPreview.bottom-left) {
  bottom: 0;
  left: 20px;
}

:local(.ChatAnchorInPreview.bottom-right) {
  bottom: 0;
  right: 20px;
}
