:local(.wrapper){
  overflow-y: auto;
  position: relative;
  height: 100%;
}

:local(.selectControls){
  position: absolute;
  right: 20px;
  top: 19px;
  text-align: right;
}
:local(.middot){
  margin: 0 6px;
}

:local(.zeroState){
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

:local(.zeroState) p,
:local(.zeroState) h4{
  font-weight: normal;
  color: #797979;
}
