:local(.wrapper){
}
:local(.wrapper) .label-input{
  flex: 1;
}
:local(.wrapper) .label-input input{
  width: 100%;
}
:local(.wrapper) .label-input input[disabled]{
  background: transparent;
  border: none;
  padding-left: 0;
}
:local(.wrapper) .label-input.chat-limit input{
  width: 62px;
  min-width: 62px;
}
:local(.wrapper) .label-input.chat-limit .descript{
  font-size: 13px;
}
