:local(.ScriptModal) {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  overflow: visible !important;
  width: 564px !important;
  border-radius: 10px !important;
  outline: none !important;
}

/* :local(.ScriptModal code) { */
/*   display: inline-block; */
/*   padding: 3px 3px; */
/*   border-radius: 5px; */
/*   font-family: 'courier new', monospace; */
/*   background: #f4f4f4; */
/*   border: 1px solid rgba(121, 121, 121, 0.5); */
/*   color: #797979; */
/* } */

:local(.ModalContent) {
  max-width: none !important;
  border-radius: 10px !important;
}

:local(.ModalHeader) {
  font-size: 17px;
  border-radius: 10px 10px 0 0 !important;
}

:local(.ModalBody) {
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0 1px 0 0 #bdbdbd;
}

:local(.ModalFooter) {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  padding-bottom: 11px;
  padding-right: 20px;
  border-radius: 0 0 10px 10px !important;
  text-align: right;
}

:local(.Heading) {
  font-weight: bold;
}

:local(.Info) {
  margin-top: 4px;
  margin-bottom: 10px;
  font-size: 13px;
  color: #797979;
}

:local(.CloseButton) {
  width: 78px;
  height: 38px;
  padding: 0;
  font-size: 17px;
  border-radius: 5px;
  border: 1px solid #797979;
  color: #797979;
  background: #fff;
}

/* :local(.BackButton) { */
/*   display: flex; */
/*   justify-content: center; */
/*   align-items: center; */
/*   width: 78px; */
/*   height: 38px; */
/*   padding: 0; */
/*   font-size: 17px; */
/*   border-radius: 5px; */
/*   border: 1px solid #797979; */
/*   margin-right: 10px; */
/*   color: #797979; */
/*   background: #fff; */
/* } */

/* :local(.SaveButton) { */
/*   display: flex; */
/*   justify-content: center; */
/*   align-items: center; */
/*   height: 38px; */
/*   padding: 0 18px; */
/*   font-size: 17px; */
/*   border-radius: 5px; */
/* } */

:local(.DownloadButton) {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 38px;
  padding: 0 16px;
  font-size: 15px;
  color: #2e7de1 !important;
  background: #fff;
  border: 1px solid #2e7de1;
  border-radius: 5px;
  text-decoration: none !important;
}

:local(.CodeSection) {
  position: relative;
  height: 164px;
  max-height: 164px;
  line-height: normal;
  margin-bottom: 20px;
}

:local(.Pre) {
  width: 100%;
  height: 100%;
  padding: 15px;
  font-family: monospace;
  font-size: 13px;
  line-height: normal;
  overflow-y: auto;
}

:local(.CopyButton) {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 111px;
  height: 36px;
  padding: 0 16px;
  font-size: 15px;
  color: #2e7de1 !important;
  background: #fff;
  border: 1px solid #2e7de1;
  border-radius: 5px;
}