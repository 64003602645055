:local(.WidgetList) {
  display: flex;
  flex-direction: column;
  min-height: 0; /* FF fix */
  position: relative;
  width: 100%;
  flex: 1;
}

:local(.Content) {
  overflow-y: auto;
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 64px;
}

:local(.CreateWidget) {
  /* height: 30px; */
  position: relative;
}
