:local(.EditPanel) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.isSubmitting {
  /* <TODO> Submitting state */
}

:local(.Input) {
  width: 100%;
  padding: 0 10px;
  transition: border 240ms linear;
  border-radius: 5px;
  height: 35px;
}

:local(.Check) {
  background: url(../../../../img/group-2.svg) center no-repeat;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 10px;
}

:local(.Check:hover) {
  background: url(../../../../img/check-hovered.svg) center no-repeat;
}

:local(.Input:focus),
:local(.Input:active) {
  border: 1px solid #2e7de1;
  border-radius: 5px;
}

:local(.Input::placeholder) {
  color: #bdbdbd;
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  font-family: Roboto;
}

:local(.Controls) {
  display: flex;
  margin-left: 10px;
}

:local(.Img) {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

:local(.Img + .Img) {
  margin-left: 10px;
}

:local(.isDisabled) {
  opacity: 0.3;
  cursor: default;
  background: url(../../../../img/group-2.svg) center no-repeat !important;
}

:local(.Error) {
  font-size: 13px;
  color: #ff0000;
  font-weight: 300;
  padding-bottom: 6px;
}

:local(.editingContainer) {
  padding: 0 30px;
  height: 70px;
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

:local(.increaseHeight) {
  height: 80px;
  padding-top: 12px;
}

:local(.increaseHeight .Input:focus),
:local(.increaseHeight .Input:active) {
  transition: border 240ms linear;
  border: 2px solid #ff0000;
}
