/* react-modal */
.ReactModalPortal{
    .modal-content{
        box-shadow: 0 13px 52px rgba(0,0,0,.27);
        -webkit-box-shadow: 0 13px 52px rgba(0,0,0,.27);
        min-width: 300px;
        max-width: 600px;
        border-radius: 3px;
    }
    .modal-footer{
        border:none;
    }
}
.ReactModal__Content{
    border: 0 !important;
    padding: 0 !important;
    &:focus {
      outline: none !important;
  }
}
.ReactModal__Overlay{
    z-index: 9999;
    background: rgba(0,0,0,0.11) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
