:local(.loadingWrapper){
	padding: 88px 0;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  color: #2e7de1;
  border-radius: 10px;
  border: solid 1px #bdbdbd;
  background: #f4f4f4;
}
:local(.loadingWrapper) span{
	padding-left: 3px;
}

:local(.wrapper) .public_fixedDataTableRow_main{
  box-shadow: 0 -1px 0 0 #f4f4f4;
}

:local(.wrapper) .fixedDataTableRowLayout_main,
:local(.wrapper) .fixedDataTableLayout_rowsContainer {
  overflow: visible;
}

:local(.wrapper) .fixedDataTableLayout_footer.public_fixedDataTable_footer,
:local(.wrapper) .fixedDataTableLayout_footer.public_fixedDataTable_footer .public_fixedDataTableCell_main{
  background-color: #eff5fd;
  font-weight: bold;
  font-size: 15px;
  color: #000000;
}
:local(.wrapper) .fixedDataTableLayout_footer.public_fixedDataTable_footer:after{
  height: 2px;
  bottom: -2px;
  width: 100%;
  content: "";
  position: absolute;
  background: #eff5fd;
  left: 0;
}

:local(.wrapper) .public_fixedDataTableRow_highlighted,
:local(.wrapper) .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main{
  background: none;
}

:local(.wrapper) .fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main:first-child{
  padding-left:0;
}
:local(.wrapper) .fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main:last-child{
  padding-right:0;
}

:local(.wrapper) .public_fixedDataTableCell_cellContent{
  padding: 3px 15px 0 15px;
}
