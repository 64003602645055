:local(.StreamsModal) {
  position: absolute;
  top: 42% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  overflow: visible !important;
  width: 500px !important;
  border-radius: 10px !important;
  outline: none !important;
}

:local(.ModalContent) {
  border-radius: 10px !important;
}

:local(.ModalHeader) {
  font-size: 17px;
  border-radius: 10px 10px 0 0 !important;
}

:local(.ModalBody) {
  padding: 20px 20px 32px 20px;
  box-shadow: 0 1px 0 0 #bdbdbd;
  overflow-y: scroll;
}

:local(.ModalFooter) {
  padding-top: 12px;
  padding-bottom: 11px;
  padding-right: 20px;
  border-radius: 0 0 10px 10px !important;
}

:local(.Heading) {
  font-weight: bold;
}

:local(.RuleName), :local(.Description), :local(.Pattern) {
  margin-bottom: 5px;
  font-size: 13px;
  height: 18px;
  font-family: Roboto;
  font-weight: bold;
  text-align: left;
  color: #797979;
}

:local(.RuleNameSection .RuleNameInput) {
  width: 100%;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #797979;
  font-family: Roboto;
  font-size: 15px;
  text-align: left;
  padding-left: 10px;
}

:local(.RuleNameSection) {
  margin-bottom: 20px;
}

:local(.DescriptionSection) {
  margin-bottom: 16px;
}

:local(.PatternInfo) {
  font-family: Roboto;
  font-size: 13px;
  text-align: left;
}

:local(.PatternSection .PatternInput) {
  margin-top: 10px;
  width: 100%;
  min-height: 56px;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #797979;
  font-family: Roboto;
  font-size: 15px;
  text-align: left;
  padding-left: 10px;

}

:local(.DescriptionSection .DescriptionInput) {
  width: 100%;
  min-height: 70px;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #797979;
  font-family: Roboto;
  font-size: 15px;
  text-align: left;
  padding-left: 10px;
}

:local(.CancelButton) {
  width: 78px;
  height: 38px;
  padding: 0;
  font-size: 17px;
  border-radius: 5px;
  border: 1px solid #797979;
  margin-right: 20px;
  color: #797979;
  background: #fff;
}

:local(.SaveButton) {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  font-size: 17px;
}

:local(.Weak) {
  color: #797979;
}

:local(.Error) {
  font-size: 13px;
  color: #ff0000;
  font-weight: 300;
  padding-bottom: 6px;
}

:local(.CloseButton) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93px;
  height: 38px;
  padding: 0;
  font-size: 17px;
  border-radius: 3px;
  border: 1px solid #797979;
  color: #797979;
  background: #fff;
}

:local(.iconsContainer) {
  display: flex;
}

:local(.Trash) {
  background: url(../../../../img/trash-icon.svg) center no-repeat;
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

:local(.Trash:hover) {
  background: url(../../../../img/delete-hover.svg) center no-repeat;
}

:local(.PatternRow) {
  display: flex;
  align-items: center;
}

:local(.PatternWrapper) {
  width: 100%;
}

:local(.PatternInput:focus .PatternWrapper) {
  width: 100%;
}


:local(.Option) {
  /* background: red; */
}
