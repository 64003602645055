.tether-element .Select-menu-outer {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #d6d6d6;
  margin-top: 0;
}
.tether-element .Select-menu-outer .Select-option{
	padding: 10px;
	box-shadow: inset 0 -1px 0 0 #e5e5e5;
	color: #000;
}
.tether-element .Select-menu-outer .Select-option:hover{
 box-shadow: inset 2px 0 0 0 #2e7de1, inset 0 -1px 0 0 #e5e5e5;
}
.tether-element .Select-menu-outer .Select-option.is-selected{
	position: relative;
}
.tether-element .Select-menu-outer .Select-option.is-selected .Select-checked:after{
	content: "";
	width: 14px;
	height: 14px;
	background: url(../src/img/icon-check-small.svg) no-repeat;
	background-size: contain;
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -5px;
}
.tether-element .Select-menu-outer .Select-option.is-disabled{
	color: #bdbdbd;
	background-color: #f4f4f4;
}
.tether-element{
	z-index: 1;
}
